.serviceItemsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0px auto;
    padding: 0 120px;
}

.service_item {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin-bottom: 35px;

}

.service_item>img {
    height: 40vh;
    width: auto;
}

.service_item>div {
    padding: 0 35px;
    border: 1px solid #cbd5e14a;
}

.service_item>div>h3 {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 500;
}

.service_item>div>h3:hover {
    color: rgba(0, 0, 255, 0.705);
    transition: 400ms;
    cursor: pointer;
}

.service_item>div>p {
    margin: 24px auto 15px;
    line-height: 25px;
    color: #334155;
}

@media only screen and (max-width: 1101px) {
    .serviceItemsWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 10px auto 0px;
        padding: 0 120px;
        width: 75%;
    }
}


@media only screen and (max-width: 600px) {
    .testimonial {
        margin: 27px 0 0;
        justify-content: center;

    }

    .testimonial_h1 {
        margin: 20px auto 15px;
        font-size: 32px;
        text-align: center;

    }

    .testimonial_h1_bottom {
        text-align: center;
        margin: 0px auto 25px;
        border-bottom: 4px solid rgb(62, 121, 232);
        width: 70px;
    }

    /* ----------------Part 2------------------- */
    .serviceItemsWrapper {
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap;
        justify-content: space-between; */
        margin: 16px auto;
        padding: 0 10px;
        width: 350px;
    }

    .service_item {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* margin-bottom: 0px;
        padding-bottom: 35px;
        border-bottom: 1px solid #cbd5e1; */
    }

    .service_item>img {
        height: 35vh;
        width: auto;
    }

    .service_item>h3 {
        margin-top: 15px;
        font-size: 20px;
        font-weight: 500;
    }


    .service_item>p {
        margin: 32px auto 15px;
        line-height: 25px;
        color: #334155;
    }

    .service_item>.read_more_link {
        text-align: right;

    }

}