textarea:invalid input:invalid {
    border: 2px dashed red;
}

textarea:valid input:valid {
    border: 2px solid lime;
}

.loanFormWrapper {
    margin-top: 77px;

}

.loanFormWrapper>h1 {
    color: #1e3a8a;
    text-align: center;
    padding: 25px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #f3f3f3;
    /* border-bottom: 1px solid #94a3b8; */
}

.loanFormWrapper>h1:hover {
    color: #2459bc;
}

.loanFormWrapper>.loan_info {
    font-size: 20px;
    line-height: 35px;
    text-align: center;
}

.loanFormWrapper>.loan_info>.alert {
    color: #3b82f6;
}

.fromForLoan {
    width: 600px;
    margin: 45px auto;
}

.loan_form>div {
    display: block;
    margin: 25px auto;
}

.loan_form>div>label {
    display: block;
    font-size: 18px;
    margin: 10px auto;
}

.loan_form>div>input,
.loan_form>div>textarea {
    display: block;
    width: 100%;
}

.loan_form>div>input {
    font-size: 18px;
    height: 35px;
    padding: 0 20px;
    accent-color: #2459bc;
    border: 1px solid #d1d5dba3;
    border-radius: 3px;
}

.loan_form>div>input:focus {
    border: 0px solid transparent;
}




.loan_btn {
    padding-top: 35px;
    border-top: 1px solid #f3f3f3;

}

.loan_btn>.cancel {
    width: 100px;
}

@media only screen and (max-width: 600px) {

    .loanFormWrapper {
        margin: 77px 15px 10px;

    }

    .loanFormWrapper>h1 {
        font-size: 30px;
        text-align: center;
        padding: 0 0 15px;
        margin-bottom: 10px;
        border-bottom: 1px solid #f3f3f3;
        /* border-bottom: 1px solid #94a3b8; */
    }

    .fromForLoan {
        width: auto;
        margin: 45px 15px;
    }

    .loan_form>div {
        display: block;
        margin: 25px auto;
    }

    .loan_form>div>label {
        display: block;
        font-size: 18px;
        margin: 10px auto;
    }

    .loan_form>div>input,
    .loan_form>div>textarea {
        display: block;
        margin: auto 0;
        width: 100%;
    }
}