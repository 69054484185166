.border {
    border: 1px solid transparent;
    border-color: #e5e7eb;
    margin: 15px;
}

.profil_info {
    display: flex;
    justify-content: space-between;
    margin: 10px 150px;

}

.profil_info .profil_content_1 {
    width: 30%;
    text-align: center;
}

.profil_content_1 .boder {
    text-align: center;
    position: fixed;
}

.profil_info .profil_content_2 {
    width: 65%;
    padding: 15px 35px;

}

.profil_info .border h2 {
    margin-top: 15px;
}

.profil_info .border .idPicture {
    margin: 0;
    padding: 0;
}

.profil_info .border img {
    height: 30vh;
    width: 100%;
}

.profil_info .picture {
    height: 150px;
    width: 150px;
    background-color: #9f1239;
    border: 1px solid transparent;
    border-radius: 100%;
    text-align: center;
    position: relative;
    /* top: 50%;
    left: 50%; */
    translate: 40%;
    margin: 25px 0;

}

.profil_info .picture h1 {
    color: #ffffff;
    font-weight: 600;
    font-size: 48px;
    position: relative;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
}

/* ***************part 2******** */
.profil_content_2 .profil_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
}

.profil_content_2>.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    font-size: 16px;
}

.profil_content_2>.row>.label {
    color: #334155;
    font-weight: 300;
    font-size: 18px;

}

/* *************security part *********/
.profil_content_3 {
    margin: 10px 150px;
    padding: 15px 35px;
}

.profil_content_3>.profil_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
}

.profil_content_3>.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    font-size: 16px;
    text-align: center;
    margin: 0 auto;
    width: 200px;
}

.profil_content_3>.row>.label {
    color: #334155;
    font-weight: 300;
    font-size: 18px;

}

@media (max-width: 1201px) {
    .profil_info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 10px 20px;
        padding: 15px 0px;
    }

    .profil_info .profil_content_1 {
        width: auto;
        margin: 0 15px;
        text-align: center;
    }

    .profil_content_1 .boder {
        text-align: center;
        position: fixed;
    }

    .profil_info .profil_content_2 {
        width: auto;
        margin: 0 15px;
        padding: 10px 15px;

    }

    .profil_info .border h2 {
        margin-top: 15px;
        font-size: 18px;
    }

    .profil_info .picture {
        height: 150px;
        width: 150px;
        background-color: #9f1239;
        border: 1px solid transparent;
        border-radius: 100%;
        text-align: center;
        position: relative;
        /* top: 50%;
        left: 50%; */
        translate: 40%;
        margin: 25px 0;

    }

    .profil_info .picture h1 {
        color: #ffffff;
        font-weight: 600;
        font-size: 48px;
        position: relative;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
    }

    /* ***************part 2******** */
    .profil_content_2 .profil_title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 35px;
    }

    .profil_content_2>.row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 18px;
        font-size: 16px;
    }

    .profil_content_2>.row>.label {
        color: #334155;
        font-weight: 300;
        font-size: 14px;
        /* min-width: 115px; */

    }

    .profil_content_2>.row>.label_value {
        color: #1e293b;
        /* font-weight: 500; */
        font-size: 14px;

    }

    /* *************security part *********/
    .profil_content_3 {
        margin: 10px 20px;
        padding: 15px 25px;
    }

    .profil_content_3>.profil_title {
        margin-bottom: 25px;
        font-size: 14px;
    }

    .profil_content_3>.row {
        margin-bottom: 15px;
        font-size: 16px;
        text-align: center;
        margin: 0 auto;
        width: 200px;
    }

    .profil_content_3>.row>.label {
        color: #334155;
        font-weight: 300;
        font-size: 18px;

    }
}