@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&amp;display=swap);
/* Global Styles for Navbar */
nav {
    position: fixed;
    margin: 0;
    background-color: rgba(0, 0, 255, 0.254);
    z-index: 1000;
    top: 0;
    width: 100%;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 30px;
}

.logo_link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: left;
    width: 20%;
}

.title {
    font-size: 32px;
    font-weight: 700;
    color: #ffffff;
    background-color: transparent;
    margin-left: 50px;
    text-decoration: none;
    white-space: nowrap;
}

.part_1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    margin-right: 10%;
    background-color: transparent;
}

.part_1>.desktop-link {
    color: #ffffff;
    background-color: transparent;
    text-align: center;
    text-decoration: none;
    padding: 10px 30px;
    font-size: 18px;
    transition: transform 500ms;
    font-weight: bold;

}

.part_1>.desktop-link:hover {
    color: #ffffff;
    font-weight: bold;
    transform: scale(1.15);
}

.navbar-container .link {
    display: block;
    text-decoration: none;
}

/* ***************Part2********* */
.part_2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    min-width: 40%;
    padding: 0 4% 0 11%;
    background-color: transparent;

}

.part_2>.desktop-link {
    border: 1px solid transparent;
    border-color: #ffffff;
    border-radius: 7px;
    text-decoration: none;
    color: #ffffff;
    background-color: transparent;
    padding: 10px 20px;
    margin: 0 15px;
    font-size: 16px;
    transition: transform 500ms;

}

.part_2>.desktop-link:hover {
    color: blue;
    background-color: #ffffffd8;
    /* margin: 0 15px; */
    transform: scale(1.15);
}



/* ----------------------------- */
/* Styles pour Menu Mobile */


/* Styles pour Drawer Mobile */
.asly {
    position: relative;
}

.sousmenu {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: -75%;
    background-color: rgba(0, 0, 255, 0.254);
    border: 1px solid transparent;
    border-radius: 15px;
    padding: 25px 0;
    width: 250px;
    transition: visibility 0s, opacity 0.5s;
}

.sousmenu>.link {
    color: #ffffff;
    text-decoration: none;
    font-size: 24px;
    padding: 5px 0;
    width: 100%;
    transition: transform 500ms;
}

.sousmenu>.link:hover {
    color: #ffffff;
    font-size: 32px;
    font-weight: 600;
    transform: scale(1.15);
}







/*sous menu part */
/* Style for the "A-sly" menu */

.asly:hover .sousmenu {
    visibility: visible;
    display: flex;
    flex-direction: column;
    opacity: 1;
}
.footerWrapper {
    width: 100%;
    margin-top: 45px;
    /* margin-top: 77px; */
    background-color: rgba(156, 156, 246, 0.172);

}


.footer_top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 45px 25px 25px;
    margin-bottom: 24px;
    /* background-color: rgba(156, 156, 246, 0.073); */
}

.footer_top_col_1 {
    width: 450px;
    /* margin-bottom: 45px; */
    line-height: 25px;
}

.footer_top_col_1 p {
    color: #334155;
}

.footer_top_col {
    /* margin-bottom: 45px; */
    line-height: 35px;
}

.footer_top_col .link {
    text-decoration: none;
    margin-bottom: 25px;
    color: rgb(35, 34, 49);
}

.footer_top_col>.link:hover {
    text-decoration: underline;
}

.footer_bottom {
    text-align: center;
    line-height: 25px;
    margin: 35px auto;
}

.footer_bottom>p {
    color: #64748b;
}

.footerWrapper ion-icon {
    font-size: 24px;
    color: rgba(0, 0, 255, 0.668);
    margin: 5px;
}

.langue_btn {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.702) !important;
}

.langue_btn:hover {
    font-size: 26px;
    cursor: pointer;
}

.langueBoxContainer {
    height: 45px;
    width: 150px;
    margin-left: 50px;
    margin-bottom: 7px;
}

.langueBoxContainer>select {
    background-color: #ffffff;
    /* opacity: 1; */
    font-weight: 500;
    border: 1px solid transparent;
    font-size: 18px;
    padding: 7px 10px;
}

.langueBoxContainer>select>option:hover {
    background-color: #5d74b8;
}

@media only screen and (max-width: 600px) {
    .footerWrapper {
        padding: auto 15px;
        margin-top: 25px;
        /* margin-top: 77px; */
    }


    .footer_top {
        display: flex;
        flex-direction: column;
        padding: 45px 25px 15px;
        margin-bottom: 24px;
    }

    .footer_top_col_1 {
        width: 350px;
        /* margin-bottom: 45px; */
        justify-content: center;
        align-items: center;

        line-height: 25px;
    }

    .footer_top_col {
        /* margin-bottom: 45px; */
        line-height: 35px;
    }

    .footer_top_col .link {
        text-decoration: none;
        margin-bottom: 5px;

    }

    .footerWrapper h2 {
        margin-top: 25px;
    }

    .footer_bottom {
        text-align: center;
        line-height: 25px;
        margin: 15px auto;
    }

    .footerWrapper ion-icon {
        font-size: 24px !important;
        color: rgba(0, 0, 255, 0.668);
        margin: 5px;
    }

    .langueBoxContainer {
        margin-left: 20px;
    }

}
.error_container {
    margin: 145px auto 77px;
    background-color: #ffffff;
    text-align: center;
    opacity: 1;

}

.error_container>p {
    font-size: 18px;
}

.status {
    color: #6366f1;
    font-weight: 300;
    font-size: 200px;
    opacity: 0.5;

}
.testimonial {
    margin: 77px 0 0;
    justify-content: center;

}

.testimonial_h1 {
    margin: 80px auto 15px;
    font-size: 32px;
    text-align: center;

}

.testimonial_h1_bottom {
    text-align: center;
    margin: 0px auto 15px;
    border-bottom: 4px solid rgb(62, 121, 232);
    width: 70px;
}

/* Slides */

.btn {
    width: 95%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    top: 370px;
    cursor: pointer;
    max-width: 1700px;
}

.text-blk {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    line-height: 25px;
}

.responsive-container-block {
    min-height: 75px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    justify-content: flex-start;
}

.responsive-container-block.bg {
    flex-direction: column;
    align-items: center;
    height: auto;
    max-width: 1700px;
}

.text-blk.title {
    font-size: 46px;
    line-height: 64px;
    font-weight: 900;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
}

.container-block {
    min-height: 75px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    display: block;
}

.swiper-container {
    width: 85%;
    max-width: 1520px;
    height: auto;
    overflow: hidden;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-position: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    transition: opacity 1s ease, visibility 1s ease;
}

.swiper-slide.swiper-slide-next {
    opacity: 1;
}

.swiper-slide.swiper-slide-next .content .quotes {
    color: rgb(3, 169, 244);
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.responsive-container-block.content {
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 20px 7px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    position: relative;
    margin-top: 0px;
    margin-right: 20px;
    margin-bottom: 50px;
    margin-left: 20px;
}

.text-blk.name {
    font-size: 24px;
    line-height: 32px;
    font-weight: 900;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 10px;
}

.text-blk.desig {
    font-size: 20px;
    line-height: 24px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
}

.text-blk.info {
    max-width: 550px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 25px;
    margin-left: 0px;
    font-size: 20px;
    line-height: 30px;
}

.text-blk.quotes {
    position: absolute;
    left: 20px;
    font-size: 144px;
    line-height: 32px;
    top: 17px;
    font-weight: 900;
    color: rgb(0, 0, 0);
}

.container-block.swiper-button-prevs {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.container-block.swiper-button-nexts {
    width: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.profile-img {
    width: auto;
    max-width: 130px;
    margin-top: 20px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    min-height: 130px;
}

.swiper-slide img.review {
    width: 125px;
}

.pagination-button {
    width: 36px;
    height: 36px;
}

.swiper-pagination {
    min-height: auto;
    position: relative;
}

.swiper-pagination-bullet {
    margin: 0 7px;
    /* Espacement horizontal entre les dots 
    .swiper-pagination-bullet est une classe 
    générée automatiquement par la bibliothèque 
    Swiper pour styliser chaque point de pagination
    */
}

.container-block {
    margin: 0 5px !important;

}

.content {
    margin-top: 40px;
    margin-right: 20px;
    margin-bottom: 120px;
    margin-left: 20px;
}

@media (max-width: 1024px) {
    .swiper-slide {
        opacity: 0.3;
    }

    .swiper-slide.swiper-slide-next .content .quotes {
        color: rgb(0, 0, 0);
    }

    .swiper-slide.swiper-slide-next {
        opacity: 1;
    }

    .swiper-slide.swiper-slide-next .content .quotes {
        color: rgb(3, 169, 244);
    }
}

@media (max-width: 768px) {
    .swiper-slide {
        opacity: 0.3;
    }

    .swiper-slide.swiper-slide-next {
        opacity: 0.3;
    }

    .swiper-slide.swiper-slide-next .content .quotes {
        color: rgb(0, 0, 0);
    }

    .swiper-slide.swiper-slide-active {
        opacity: 1;
    }

    .swiper-slide.swiper-slide-active .content .quotes {
        color: rgb(3, 169, 244);
    }

    .btn {
        width: 98%;
    }

    .text-blk.title {
        font-size: 32px;
    }
}

@media (max-width: 500px) {
    .swiper-slide {
        opacity: 0.3;
    }

    .swiper-slide .content .quotes {
        color: rgb(0, 0, 0);
    }

    .swiper-slide.swiper-slide-active {
        opacity: 1;
    }

    .swiper-slide.swiper-slide-active .content .quotes {
        color: rgb(3, 169, 244);
    }

    .btn {
        width: 99%;
    }

    .text-blk.info {
        font-size: 17px;
        line-height: 28px;
    }

    .text-blk.name {
        font-size: 22px;
        line-height: 30px;
    }

    .text-blk.desig {
        font-size: 17px;
        line-height: 28px;
    }

    .btn {
        display: none;
    }

    .responsive-container-block.content {
        margin-top: 40px;
        margin-right: 20px;
        /* margin-bottom: 80px; */
        margin-bottom: 20px;
        margin-left: 20px;
    }

    .title {
        font-size: 32px;
    }

    .text-blk.title {
        font-size: 32px;
    }
}


/* Responsive */

/* *,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    margin: 0;
} */

.wk-desk-1 {
    width: 8.333333%;
}

.wk-desk-2 {
    width: 16.666667%;
}

.wk-desk-3 {
    width: 25%;
}

.wk-desk-4 {
    width: 33.333333%;
}

.wk-desk-5 {
    width: 41.666667%;
}

.wk-desk-6 {
    width: 50%;
}

.wk-desk-7 {
    width: 58.333333%;
}

.wk-desk-8 {
    width: 66.666667%;
}

.wk-desk-9 {
    width: 75%;
}

.wk-desk-10 {
    width: 83.333333%;
}

.wk-desk-11 {
    width: 91.666667%;
}

.wk-desk-12 {
    width: 100%;
}

@media (max-width: 1024px) {
    .wk-ipadp-1 {
        width: 8.333333%;
    }

    .wk-ipadp-2 {
        width: 16.666667%;
    }

    .wk-ipadp-3 {
        width: 25%;
    }

    .wk-ipadp-4 {
        width: 33.333333%;
    }

    .wk-ipadp-5 {
        width: 41.666667%;
    }

    .wk-ipadp-6 {
        width: 50%;
    }

    .wk-ipadp-7 {
        width: 58.333333%;
    }

    .wk-ipadp-8 {
        width: 66.666667%;
    }

    .wk-ipadp-9 {
        width: 75%;
    }

    .wk-ipadp-10 {
        width: 83.333333%;
    }

    .wk-ipadp-11 {
        width: 91.666667%;
    }

    .wk-ipadp-12 {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .wk-tab-1 {
        width: 8.333333%;
    }

    .wk-tab-2 {
        width: 16.666667%;
    }

    .wk-tab-3 {
        width: 25%;
    }

    .wk-tab-4 {
        width: 33.333333%;
    }

    .wk-tab-5 {
        width: 41.666667%;
    }

    .wk-tab-6 {
        width: 50%;
    }

    .wk-tab-7 {
        width: 58.333333%;
    }

    .wk-tab-8 {
        width: 66.666667%;
    }

    .wk-tab-9 {
        width: 75%;
    }

    .wk-tab-10 {
        width: 83.333333%;
    }

    .wk-tab-11 {
        width: 91.666667%;
    }

    .wk-tab-12 {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .wk-mobile-1 {
        width: 8.333333%;
    }

    .wk-mobile-2 {
        width: 16.666667%;
    }

    .wk-mobile-3 {
        width: 25%;
    }

    .wk-mobile-4 {
        width: 33.333333%;
    }

    .wk-mobile-5 {
        width: 41.666667%;
    }

    .wk-mobile-6 {
        width: 50%;
    }

    .wk-mobile-7 {
        width: 58.333333%;
    }

    .wk-mobile-8 {
        width: 66.666667%;
    }

    .wk-mobile-9 {
        width: 75%;
    }

    .wk-mobile-10 {
        width: 83.333333%;
    }

    .wk-mobile-11 {
        width: 91.666667%;
    }

    .wk-mobile-12 {
        width: 100%;
    }
}
.service {
    margin: 50px auto 0;
}

.service_part_1_h1 {
    margin: 80px auto 15px;
    font-size: 32px;
    text-align: center;
}

.service_part_1_h1_bottom {
    text-align: center;
    margin: 0px auto 66px;
    border-bottom: 4px solid rgb(62, 121, 232);
    width: 70px;
}

.service_part_2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    padding: 0 20px;
}

.service_part_2_content {
    width: 300px;
    margin: 0 15px 55px;
    line-height: 27px;
}

.service_part_2_content>a {
    text-decoration: none;
    font-size: 24px;
    font-weight: 500;
}

.service_part_2_content>p {
    color: #1e293b;
}

.service ion-icon {
    font-size: 48px;
    color: blue;
}


@media only screen and (max-width: 600px) {

    .service {
        margin: 50px auto;
    }

    .service_part_1_h1 {
        margin: 20px auto 15px;
        font-size: 28px;
        text-align: center;
    }

    .service_part_1_h1_bottom {
        text-align: center;
        margin: 0px auto 36px;
        width: 70px;
    }

    .service_part_2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 100%;
        padding: 0 25px;
    }

    .service_part_2_content {
        width: auto;
        margin: 0 10px 35px;
        line-height: 27px;
    }

    .service_part_2_content>a {
        text-decoration: none;
        font-size: 20px;
        font-weight: 500;
    }

    .service ion-icon {
        font-size: 72px !important;
        color: rgba(0, 0, 255, 0.785);
    }

}
.home_part_2 {
    margin: 0px 0px 65px;
    height: auto;
    /*added*/
}

.home_part_2_h1 {
    margin: 80px auto 15px;
    font-size: 32px;
    text-align: center;
}

.home_part_2_h1_bottom {
    text-align: center;
    margin: 0px auto 66px;
    border-bottom: 4px solid rgb(62, 121, 232);
    width: 70px;
}


.slider_part {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: inherit;
    padding: 0 105px;
    margin-bottom: 70px;

}

.home_part_2_img_container {
    /* margin: 10px auto; */
    background-image: url(/static/media/why-us-bg.23a03f07.jpg);
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    justify-content: center;
    /* background-size: contain; */
    height: inherit;
    /* height: 95vh; */
    width: 40%;
    /* margin-bottom: 70px; */


}

.slide_wrapper {
    width: 60%;
    height: inherit;
    /* height: 95vh; */
    padding: 125px 55px;
    justify-content: center;
    background-color: rgba(194, 194, 241, 0.236);
}

.slider_content {
    padding: 0px 25px;
    height: auto;
}

.slider_content_h2 {
    font-size: 36px;
    font-weight: 500;
}

.slider_content_h3 {
    font-size: 20px;
    margin: 35px 0;
    color: rgb(68, 77, 118);

}

.slider_content_p {
    /* height: inherit; */
    font-size: 18px;
    line-height: 25px;
}

/* @media only screen and (max-width: 600px) { */
/* @media (max-width: 640px) { */
@media (max-width: 600px) {

    .home_part_2 {
        margin: 0px 25px 15px;
    }

    .home_part_2_h1 {
        margin: 30px auto 15px;
        font-size: 24px;
        text-align: center;

    }

    .home_part_2_h1_bottom {
        text-align: center;
        margin: 0px auto 36px;
        border-bottom: 4px solid rgb(62, 121, 232);
        width: 70px;
    }


    .slider_part {
        display: flex;
        flex-direction: column;
        padding: 0 5px;
        margin-bottom: 0px;

    }

    .home_part_2_img_container {

        margin: 10px auto;
        /* background-size: contain;
        background-repeat: round; */
        height: 39vh;
        width: 100%;

        margin-bottom: 0px;

    }

    .slide_wrapper {
        /* width: 60%; */
        height: auto;
        width: 100%;
        padding: 0px 0px;
        background-color: #ffffff;
    }

    .slider_content {
        padding: 10px;
        background-color: rgba(194, 194, 241, 0.236);

    }

    .slider_content_h2 {
        font-size: 20px;
        font-weight: 500;
    }

    .slider_content_h3 {
        font-size: 18px;
        margin: 15px 0;
        color: rgb(68, 77, 118);

    }

    .slider_content_p {
        font-size: 16px;
        line-height: 20px;
    }
}
.home_part_3 {
    margin: 50px auto;
    background-image: url(/static/media/cta-bg.598bd60b.jpg);
    height: auto;
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position-y: left;
    background-size: cover;
    justify-content: center;
    text-align: center;
    opacity: 0.85;
}

.home_part_3_color {
    margin: 0;
    padding: 100px 0;
    background-color: rgba(0, 0, 255, 0.285);
    height: inherit;
    position: relative;
}

.home_part_3_h2 {
    margin: auto;
    font-size: 35px;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 45px;
    width: 600px;
    line-height: 45px;

}

.home_part_3_p {
    margin: auto;
    font-size: 25px;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 65px;
    width: 450px;
    line-height: 30px;

}

.home_part_3_btn_div {
    position: absolute;
    margin: 0 auto;
    width: 100%;
}

@keyframes heartbeat {

    0%,
    100% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(1.2);
    }

    75% {
        transform: scale(1.1);
    }
}

.home_part_3_btn {
    color: #ffffff;
    background-color: rgb(62, 121, 232);
    text-decoration: none;
    border: 1px solid transparent;
    border-color: rgb(62, 121, 232);
    border-radius: 50px;
    box-shadow: 1px 1px 1px 0.5px rgb(62, 121, 232);
    padding: 15px 45px;
    font-weight: 300;
    font-size: 18px;
    animation: heartbeat 1s ease-in-out 1s infinite alternate !important;
    display: inline-block;
    transition: transform 500ms;


}


@media only screen and (max-width: 600px) {
    .home_part_3 {
        /* background-size: contain;
        background-repeat: no-repeat; */
        margin: 50px auto;
        height: auto;
        /* height: 24vh; */
        justify-content: center;
    }

    .home_part_3_color {
        margin: 0;
        padding: 15px 20px;
        height: inherit;

    }


    .home_part_3_h2 {
        margin: 0;
        font-size: 24px;
        margin-bottom: 10px;
        padding: auto;
        line-height: 30px;
        text-align: center;
        width: auto;

    }

    .home_part_3_p {
        margin: auto;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
        padding: auto;
        line-height: 20px;
        width: auto;

    }

    .home_part_3_btn_div {
        position: unset;
        /* position: none; */
        margin: 0 auto;
        width: 100%;
    }

    .home_part_3_btn {

        padding: 5px 15px;
        font-size: 18px;

    }

}
textarea:invalid input:invalid {
    border: 2px dashed red;
}

textarea:valid input:valid {
    border: 2px solid lime;
}

.loanFormWrapper {
    margin-top: 77px;

}

.loanAskingWrapper {
    background-color: rgba(156, 156, 246, 0.172);
    padding: 5px;
    /* padding-bottom: ; */
}

.loanFormWrapper>h1 {
    color: #1e3a8a;
    text-align: center;
    padding: 25px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #f3f3f3;
    /* border-bottom: 1px solid #94a3b8; */
}

.loanFormWrapper>h1:hover {
    color: #2459bc;
}

.loanFormWrapper>.loan_info {
    font-size: 20px;
    line-height: 35px;
    text-align: center;
}

.loanFormWrapper>.loan_info>.alert {
    color: #3b82f6;
}

.fromForLoan {
    width: 600px;
    margin: 45px auto;
}

.loan_form>div {
    display: block;
    margin: 25px auto;
}

.loan_form>div>label {
    display: block;
    font-size: 18px;
    margin: 10px auto;
}

.loan_form>div>input,
.loan_form>div>textarea {
    display: block;
    width: 100%;
}

.loan_form>div>input {
    font-size: 18px;
    height: 35px;
    padding: 0 20px;
    accent-color: #2459bc;
    border: 1px solid #d1d5dba3;
    border-radius: 3px;
}

.loan_form>div>input:focus {
    border: 0px solid transparent;
}




.loan_btn {
    padding-top: 35px;
    border-top: 1px solid #f3f3f3;

}

.loan_btn>.cancel {
    width: 100px;
}

@media only screen and (max-width: 600px) {

    .loanFormWrapper {
        margin: 77px 15px 10px;

    }

    .loanFormWrapper>h1 {
        font-size: 30px;
        text-align: center;
        padding: 0 0 15px;
        margin-bottom: 10px;
        border-bottom: 1px solid #f3f3f3;
        /* border-bottom: 1px solid #94a3b8; */
    }

    .fromForLoan {
        width: auto;
        margin: 45px 15px;
    }

    .loan_form>div {
        display: block;
        margin: 25px auto;
    }

    .loan_form>div>label {
        display: block;
        font-size: 18px;
        margin: 10px auto;
    }

    .loan_form>div>input,
    .loan_form>div>textarea {
        display: block;
        margin: auto 0;
        width: 100%;
    }
}
/* @media only screen and (min-width: 1101px) { */
main {
    padding: 0;
}

.home_part_1 {
    margin: 64px 0 0;
    background-image: url(/static/media/hero-bg.74ec0068.jpg);
    height: auto !important;
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    justify-content: center;
    /* height: 50vh; */

}

.home_part_1_color {
    margin: 0;
    padding: 200px 80px;
    background-color: rgba(0, 0, 255, 0.400);
    /* background-color: rgba(0, 0, 255, 0.285);*/
    height: inherit;
    /* height: 100vh; */
    width: 100%;

}

.home_part_1_h1 {
    font-size: 48px;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 45px;

}

.home_part_1_p {
    font-size: 27px;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 65px;
    border-left: 4px solid rgb(62, 121, 232);
    padding-left: 25px;
}

@keyframes heartbeat {

    0%,
    100% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(1.2);
    }

    75% {
        transform: scale(1.1);
    }
}

.home_part_1_btn {
    color: #ffffff;
    background-color: rgb(62, 121, 232);
    text-decoration: none;
    border: 1px solid transparent;
    border-color: rgb(62, 121, 232);
    border-radius: 50px;
    box-shadow: 1px 1px 1px 0.5px rgb(62, 121, 232);
    padding: 15px 45px;
    font-weight: 300;
    font-size: 18px;
    animation: heartbeat 1s ease-in-out 1s infinite alternate !important;
    display: inline-block;
    transition: transform 500ms;

}

/* ***********************Part 2 **************************** */

/* ***********************Part 3 **************************** */


/* ***********************Part 4 **************************** */

.home_part_4 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 100px auto 65px;
    width: 80%;
}


.home_part_4_left {
    width: 550px;
}

.home_part_4_left_1 {
    margin: 0 0 200px;
}

.home_part_4_left_1_h2 {
    font-size: 42px;
    margin-bottom: 30px;
}

.home_part_4_left_1_div {
    display: flex;
    flex-wrap: wrap;

}

.item {
    font-size: 20px;
    /* width: 275px; */
    letter-spacing: 1px;
    line-height: 55px;

}

.home_part_4_left_2 {
    margin-bottom: 30px;

}

.home_part_4_left_2_h2 {
    font-size: 42px;
    margin-bottom: 30px;
}

.home_part_4_left_2_p {
    font-size: 18px;
    margin-bottom: 45px;

}

.home_part_4_btn {
    color: #ffffff;
    background-color: rgb(62, 121, 232);
    text-decoration: none;
    border: 1px solid transparent;
    border-color: rgb(62, 121, 232);
    border-radius: 50px;
    box-shadow: 1px 1px 1px 0.5px rgb(62, 121, 232);
    padding: 15px 45px;
    font-weight: 300;
    font-size: 18px;
    animation: heartbeat 1s ease-in-out 1s infinite alternate !important;
    display: inline-block;
    transition: transform 500ms;
}



@media only screen and (max-width: 1101px) {
    .home_part_1 {
        margin: 49px 0 0;
    }
}

@media only screen and (max-width: 600px) {
    .home_part_1 {

        margin: 49px 0 0;
        height: auto;
    }

    .home_part_1_color {
        padding: 35px 20px;
        height: inherit;
        /* height: 28vh; */
        justify-content: center;
    }

    .home_part_1_h1 {
        font-size: 24px;
        /* margin-bottom: 15px; */

    }

    .home_part_1_p {
        font-size: 17px;
        margin-bottom: 35px;
        padding-left: 25px;
    }

    .home_part_1_btn {
        font-weight: 500;
        padding: 5px 15px;
        margin-bottom: 15px;
        font-size: 14px;
        transition: transform 500ms;

    }




    /* ***********************Part 4 **************************** */

    .home_part_4 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 10px auto 15px;
        padding: 10px 30px;
        width: 100%;
    }

    .home_part_4_left {
        justify-content: center;
        width: auto;
        /* width: 300px; */
        margin: 10px 0;

    }



    .home_part_4_left_1 {
        margin: 0 0 25px;
    }

    .home_part_4_left_1_h2 {
        font-size: 24px;
        margin-bottom: 30px;
        text-align: center;
        margin: 0 auto;
        padding: 0;
    }

    .home_part_4_left_1_div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;

    }

    .item {
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 35px;
        width: auto;
        text-align: center;

    }

    .home_part_4_left_2 {
        margin-bottom: 30px;

    }

    .home_part_4_left_2_h2 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .home_part_4_left_2_p {
        font-size: 14px;
        margin-bottom: 45px;
        /* width: 300px; */
        text-align: center;
    }

    .home_part_4_btn:hover {
        margin: 25px 0 0;
        /* margin: 25px 25px 0; */
        transform: none;
    }

    .home_part_4_btn {
        justify-content: center;

        padding: 15px 45px;
        font-size: 18px;
    }

    .home_part_4_img {
        height: 50vh;
        width: 250px;
        margin: 0 auto;
        justify-content: center;
    }

}
.team {
    margin: 77px 0 0;
    justify-content: center;
    min-width: 300px;
}

.team_h1 {
    margin: 80px auto 15px;
    font-size: 32px;
    text-align: center;

}

.team_h1_bottom {
    text-align: center;
    margin: 0px auto 65px;
    border-bottom: 4px solid rgb(62, 121, 232);
    width: 70px;
}

.team_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;

}

.team_container h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
}

.team_container p {
    text-align: center;
    font-size: 14px;
    color: #64748b;
    margin: 20px 0;

}


/* @media only screen and (max-width: 1101px) {
    .team {
        margin: 37px 0 0;
    }
} */

@media only screen and (max-width: 600px) {
    .team {
        margin: 37px 0 0;
        min-width: 300px;
    }

    .team_h1 {
        margin: 0 auto 5px;
        font-size: 28px;

    }

    .team_h1_bottom {
        margin: 0px auto 45px;
        width: 70px;
    }

    /* .team_container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 100%;

    } */

    .team_container h3 {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        margin-top: 15px;
    }

    .team_container p {
        font-size: 14px;
        margin: 10px 0 35px;

    }
}
.aboutWrapper {
    width: 100%;
}

.about_top {
    margin-top: -200px;
    background-image: url(/static/media/about-header.bd8e9151.jpg);
    height: auto;
    /* height: 85vh; */
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position-y: left;
    background-size: cover;
}

.about_top_color {
    margin-top: 0px;
    padding-top: 350px;
    padding-bottom: 90px;
    background-color: rgba(0, 0, 255, 0.285);
    height: inherit;
    text-align: center;
}

.about_top_h1 {
    font-size: 48px;
    color: #ffffff;
    margin-bottom: 45px;

}

.about_top_div {
    display: flex;
    justify-content: center;
}


.about_top_div p {
    color: rgb(99, 198, 237);

}

.about_top_div .about_link {
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    text-decoration: none;
    margin-right: 4px;
}

.about_top_div>.about_link:hover {
    text-decoration: underline;
}

/* ---------------------------------------------- */
.about_part_1 {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-around;
    min-width: 300px;
    margin-top: 87px;
    padding: 0 50px;
}

.about_part_1_img>img {
    height: 35vh;
    width: auto;
}

.about_part_1_des {
    width: 600px;
}

.about_part_1_des>h1 {
    margin-bottom: 30px;
    font-size: 36px;
}

.about_part_1_des>h3 {
    margin-bottom: 30px;
    font-weight: 500;
}

.about_part_1_des>p {
    line-height: 25px;
    margin-bottom: 30px;

}


/* @media only screen and (max-width: 1101px) {

    .about_top {
        margin-top: 49px;

    }
} */

@media only screen and (max-width: 600px) {

    .about_top {
        margin-top: 49px;
        height: auto;
        /* background-size: contain;
        background-repeat: round; */

    }

    .about_top_color {
        margin-top: 0px;
        padding-top: 75px;
        height: inherit;
        text-align: center;

    }

    .about_top_h1 {
        font-size: 36px;
        margin-bottom: 15px;
    }

    .about_top_div .about_link {
        font-size: 18px;
        margin-right: 4px;
    }

    /* ---------------------------------------------- */
    .about_part_1 {
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: space-around;
        min-width: 300px;
        margin-top: 37px;
        padding: 0 50px;
    }

    .about_part_1_img>img {
        height: 27vh;
        width: auto;
    }

    /* .about_part_1_des {
        width: 600px;
    } */

    .about_part_1_des>h1 {
        margin-bottom: 15px;
        font-size: 24px;
    }

    .about_part_1_des>h3 {
        margin-bottom: 25px;
    }

    .about_part_1_des>p {
        font-size: 14px;
        line-height: 25px;
        margin-bottom: 25px;

    }
}
.insuranceWrapper {
    width: 100%;
}

.insurance_top {
    margin-top: -200px;
    background-image: url(/static/media/blog-header.ff93143c.jpg);
    height: auto;
    /* height: 85vh; */
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position-y: left;
    background-size: cover;
}

.insurance_top_color {
    margin-top: 0px;
    padding-top: 350px;
    padding-bottom: 90px;
    background-color: rgba(0, 0, 255, 0.285);
    height: inherit;
    text-align: center;
}

.insurance_top_h1 {
    font-size: 48px;
    color: #ffffff;
    margin-bottom: 45px;

}

.insurance_top_div {
    display: flex;
    justify-content: center;
}


.insurance_top_div p {
    color: blue;

}

.insurance_top_div .home_link {
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    text-decoration: none;
    margin-right: 4px;
}

.insurance_top_div>.home_link:hover {
    text-decoration: underline;
}

.insurance_bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin: 86px auto;
    padding: 0 120px;

}

.insurance_bottom_item {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin-bottom: 35px;
    padding-bottom: 35px;
    border-bottom: 1px solid #cbd5e1;
}

.insurance_bottom_item>img {
    height: 40vh;
    width: auto;
}

.insurance_bottom_item>h3 {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 500;
}

.insurance_bottom_item>h3:hover {
    color: rgba(0, 0, 255, 0.705);
    transition: 400ms;
    cursor: pointer;
}

.insurance_bottom_item>p {
    margin: 15px auto 15px;
    line-height: 25px;
    color: #334155;
}

.insurance_bottom_item>.read_more_link {
    text-align: right;
    color: rgba(0, 0, 255, 0.705);
    text-decoration: none;
    letter-spacing: 1px;
}


@media only screen and (max-width: 1101px) {

    .insurance_bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 86px auto;
        padding: 0 120px;
        width: 70%;
    }
}

@media only screen and (max-width: 600px) {

    .insurance_top {
        margin-top: 49px;
        height: auto;
        /* background-size: contain;
        background-repeat: round; */

    }

    .insurance_top_color {
        margin-top: 0px;
        padding-top: 75px;
        height: inherit;
        text-align: center;
    }

    .insurance_top_h1 {
        font-size: 36px;
        margin-bottom: 15px;

    }

    .insurance_top_div .home_link {
        font-size: 18px;
        margin-right: 4px;
    }


    /* ----------------Part 2------------------- */
    .insurance_bottom {
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap; */
        /* justify-content: center;
        align-items: center; */
        margin: 46px auto;
        padding: 0 10px;
        width: 350px;
    }

    .insurance_bottom_item {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0px;
        padding-bottom: 35px;
        border-bottom: 1px solid #cbd5e1;
    }

    .insurance_bottom_item>img {
        height: 35vh;
        width: auto;
    }

    .insurance_bottom_item>h3 {
        margin-top: 15px;
        font-size: 20px;
        font-weight: 500;
    }


    .insurance_bottom_item>p {
        margin: 15px auto 15px;
        line-height: 25px;
        color: #334155;
    }

    .insurance_bottom_item>.read_more_link {
        text-align: right;

    }
}
.teamWrapper {
    width: 100%;
}

.team_top {
    margin-top: -200px;
    background-image: url(/static/media/team-header.3a2d6315.jpg);
    /* height: 85vh; */
    height: auto;
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position-y: left;
    background-size: cover;
}

.team_top_color {
    margin-top: 0px;
    padding-top: 350px;
    padding-bottom: 90px;
    background-color: rgba(0, 0, 255, 0.285);
    height: inherit;
    text-align: center;
}

.team_top_h1 {
    font-size: 48px;
    color: #ffffff;
    margin-bottom: 45px;

}

.team_top_div {
    display: flex;
    justify-content: center;
}


.team_top_div p {
    color: rgb(99, 198, 237);

}

.team_top_div .team_link {
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    text-decoration: none;
    margin-right: 4px;
}

.team_top_div>.team_link:hover {
    text-decoration: underline;
}

/* @media only screen and (max-width: 1101px) {
    .team_top {
        margin-top: 49px;


    }

} */

@media only screen and (max-width: 600px) {

    .team_top {
        margin-top: 49px;
        height: auto;
        /* height: 28.5vh; */
        /* background-size: contain;
        background-repeat: round; */

    }

    .team_top_color {
        margin-top: 0px;
        padding-top: 75px;
        height: inherit;
        text-align: center;

    }

    .team_top_h1 {
        font-size: 36px;
        margin-bottom: 15px;
    }

    .team_top_div .team_link {
        font-size: 18px;
        margin-right: 4px;
    }

}
.serviceItemsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0px auto;
    padding: 0 120px;
}

.service_item {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin-bottom: 35px;

}

.service_item>img {
    height: 40vh;
    width: auto;
}

.service_item>div {
    padding: 0 35px;
    border: 1px solid #cbd5e14a;
}

.service_item>div>h3 {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 500;
}

.service_item>div>h3:hover {
    color: rgba(0, 0, 255, 0.705);
    transition: 400ms;
    cursor: pointer;
}

.service_item>div>p {
    margin: 24px auto 15px;
    line-height: 25px;
    color: #334155;
}

@media only screen and (max-width: 1101px) {
    .serviceItemsWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 10px auto 0px;
        padding: 0 120px;
        width: 75%;
    }
}


@media only screen and (max-width: 600px) {
    .testimonial {
        margin: 27px 0 0;
        justify-content: center;

    }

    .testimonial_h1 {
        margin: 20px auto 15px;
        font-size: 32px;
        text-align: center;

    }

    .testimonial_h1_bottom {
        text-align: center;
        margin: 0px auto 25px;
        border-bottom: 4px solid rgb(62, 121, 232);
        width: 70px;
    }

    /* ----------------Part 2------------------- */
    .serviceItemsWrapper {
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap;
        justify-content: space-between; */
        margin: 16px auto;
        padding: 0 10px;
        width: 350px;
    }

    .service_item {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* margin-bottom: 0px;
        padding-bottom: 35px;
        border-bottom: 1px solid #cbd5e1; */
    }

    .service_item>img {
        height: 35vh;
        width: auto;
    }

    .service_item>h3 {
        margin-top: 15px;
        font-size: 20px;
        font-weight: 500;
    }


    .service_item>p {
        margin: 32px auto 15px;
        line-height: 25px;
        color: #334155;
    }

    .service_item>.read_more_link {
        text-align: right;

    }

}
.serviceWrapper {
    width: 100%;
}

.service_top {
    margin-top: -200px;
    background-image: url(/static/media/services-header.204f6490.jpg);
    /* height: 85vh; */
    height: auto;
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position-y: left;
    background-size: cover;
}

.service_top_color {
    margin-top: 0px;
    padding-top: 350px;
    padding-bottom: 90px;
    background-color: rgba(0, 0, 255, 0.285);
    height: inherit;
    text-align: center;
}

.service_top_h1 {
    font-size: 48px;
    color: #ffffff;
    margin-bottom: 45px;

}

.service_top_div {
    display: flex;
    justify-content: center;
}


.service_top_div p {
    color: rgb(99, 198, 237);

}

.service_top_div .home_link {
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    text-decoration: none;
    margin-right: 4px;
}

.service_top_div>.home_link:hover {
    text-decoration: underline;
}

/* @media only screen and (max-width: 1101px) {

    .service_top {
        margin-top: 49px;


    }
} */


@media only screen and (max-width: 600px) {

    .service_top {
        margin-top: 49px;
        height: auto;
        /* background-size: contain;
        background-repeat: round; */

    }

    .service_top_color {
        margin-top: 0px;
        padding-top: 75px;
        height: inherit;
        text-align: center;
    }

    .service_top_h1 {
        font-size: 36px;
        margin-bottom: 15px;

    }

    .service_top_div {
        display: flex;
        justify-content: center;
    }

    .service_top_div .home_link {
        font-size: 18px;
        margin-right: 4px;
    }

    .service_top_div>.home_link:hover {
        text-decoration: underline;
    }



}
.contactWrapper {
    width: 100%;
}

.contact_top {
    margin-top: -200px;
    background-image: url(/static/media/contact-header.9b8d9155.jpg);
    height: auto;
    /* height: 85vh; */
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position-y: left;
    background-size: cover;
}

.contact_top_color {
    margin-top: 0px;
    padding-top: 350px;
    padding-bottom: 90px;
    background-color: rgba(0, 0, 255, 0.285);
    height: inherit;
    text-align: center;
}

.contact_top_h1 {
    font-size: 48px;
    color: #ffffff;
    margin-bottom: 45px;

}

.contact_top_div {
    display: flex;
    justify-content: center;
}


.contact_top_div p {
    color: rgb(99, 198, 237);

}

.contact_top_div .home_link {
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    text-decoration: none;
    margin-right: 4px;
}

.contact_top_div>.home_link:hover {
    text-decoration: underline;
}

.contact_bottom {
    margin: 97px auto;
    padding: 0 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.contact_bottom h1 {
    font-size: 28px;
    color: rgba(44, 44, 123, 0.839);
    margin-bottom: 12px;
}

.contact_bottom p {
    font-size: 16px;
    margin-bottom: 32px;
}

.contact_bottom_form {
    /* box-sizing: border-box; */
    display: flex;
    flex-direction: column;
    width: 500px;
}

.contact_bottom_form div {
    margin-bottom: 25px !important;
}

.contact_bottom_form input,
textarea {
    padding: auto;
    padding-left: 7px;
    font-size: 16px;
    border: 1px solid transparent;
    border-color: #d1d5dba3;
    border-radius: 5px;
}

.contact_bottom_form input {
    height: 35px;
}

.contact_bottom_form input:focus,
textarea:focus {
    border: 1px solid transparent;
    accent-color: #2459bc;
}

.contact_bottom_form textarea {
    height: 135px;
}

.contact_bottom_form_top {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
}

.contact_bottom_form_top>input {
    width: 45%;
}

.style_input_2 {
    width: 100%;
}

.contact_btn {
    margin: 10px auto;
    border: 1px solid transparent;
    border-color: blue;
    border-radius: 7px;
    text-decoration: none;
    color: #ffffff;
    background-color: rgba(0, 0, 255, 0.523);
    padding: 10px;
    font-size: 16px;
    transition: transform 200ms;
    text-align: center;
}

.contact_btn:hover {
    transform: scale(1.15);
}

.contactWrapper ion-icon {
    font-size: 48px;
    color: #2459bcab;
}

/* @media only screen and (max-width: 1101px) {

    .contact_top {
        margin-top: -200px;
    }

    .contact_top_color {
        padding-top: 300px;

    }
} */

@media only screen and (max-width: 600px) {

    .contact_top {
        margin-top: 49px;
        height: auto;
        /* background-size: contain;
        background-repeat: round; */

    }

    .contact_top_color {
        margin-top: 0px;
        padding-top: 75px;
        height: inherit;
        /* height: 28.5vh; */
        text-align: center;
    }

    .contact_top_h1 {
        font-size: 36px;
        margin-bottom: 15px;

    }

    .contact_top_div {
        display: flex;
        justify-content: center;
    }

    .contact_top_div .home_link {
        font-size: 18px;
        margin-right: 4px;
    }

    .contact_top_div>.home_link:hover {
        text-decoration: underline;
    }

    /* ------------------------------------ */
    .contact_bottom {
        margin: 37px auto;
        padding: 0 30px;
    }

    .contact_bottom h1 {
        font-size: 24px;
        margin-bottom: 12px;
    }

    .contact_bottom p {
        font-size: 14px;
        margin-bottom: 32px;
    }

    .contact_bottom_form {
        display: flex;
        flex-direction: column;
        /* width: 500px; */
    }

    .contact_bottom_form div {
        margin-bottom: 25px !important;
    }

    .contact_bottom_form input,
    textarea {
        padding: auto;
        padding-left: 7px;
        font-size: 16px;
        border-radius: 5px;
    }

    .contact_bottom_form input {
        height: 35px;
    }

    .contact_bottom_form input:focus,
    textarea:focus {
        border: 1px solid transparent;
        accent-color: #2459bc;
    }

    .contact_bottom_form textarea {
        height: 135px;
    }

    .contact_bottom_form_top {
        display: flex;
        flex-direction: column;

    }

    .contact_bottom_form_top input[type=text] {
        margin-bottom: 25px;

    }

    .contact_bottom_form_top>input {
        width: 100%;

    }

    .contact_btn {
        margin: 10px auto;
        border: 1px solid transparent;
        border-color: blue;
        border-radius: 7px;
        text-decoration: none;
        color: #ffffff;

        background-color: rgba(0, 0, 255, 0.523);
        padding: 10px;
        font-size: 16px;
        transition: transform 200ms;
        text-align: center;
    }


}
.bankAccountWrapper {
    width: 100%;
}

.insurance_top {
    margin-top: -200px;
    background-image: url(/static/media/blog-header.ff93143c.jpg);
    height: auto;
    /* height: 85vh; */
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position-y: left;
    background-size: cover;
}

.insurance_top_color {
    margin-top: 0px;
    padding-top: 350px;
    padding-bottom: 90px;
    background-color: rgba(0, 0, 255, 0.285);
    height: inherit;
    text-align: center;
}

.insurance_top_h1 {
    font-size: 56px;
    color: #ffffff;
    margin-bottom: 45px;

}

.insurance_top_div {
    display: flex;
    justify-content: center;
}

.insurance_top_div p {
    color: rgb(99, 198, 237);

}

.insurance_top_div .home_link {
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    text-decoration: none;
    margin-right: 4px;
}

.insurance_top_div>.home_link:hover {
    text-decoration: underline;
}

.insurance_top_div .home_link {
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    text-decoration: none;
    margin-right: 4px;
}

.insurance_top_div>.home_link:hover {
    text-decoration: underline;
}

/* ----------------Part 2------------------- */
.bankAccount {
    margin: 77px 0 0;
    justify-content: center;
    min-width: 300px;
}

.bankAccount_h1 {
    margin: 80px auto 15px;
    font-size: 32px;
    text-align: center;

}

.bankAccount_h1_bottom {
    text-align: center;
    margin: 0px auto 65px;
    border-bottom: 4px solid rgb(62, 121, 232);
    width: 70px;
}

/* -------------------Part 3--------------------- */
.bankAccount_content {
    width: 100%;
    margin: 25px 0;
    padding: 0 105px;
}

.bankAccount_content>div {
    margin: 25px 0;
    padding: 55px 0;
    border-bottom: 1px solid transparent;
    border-color: #cbd5e1;
}

.bankAccount_content>div>h2 {
    font-size: 27px;
    font-weight: 500;
    margin: 20px;
    color: #1e293b;
}

.bankAccount_content>div>h2:hover {
    color: rgba(0, 0, 255, 0.705);
    transition: 300ms;
    cursor: pointer;
}

.bankAccount_content>div>h3 {
    font-size: 20px;
    font-weight: 400;
    margin: 10px;
}

.bankAccount_content>div>p {
    font-size: 18px;
    margin: 15px;
    line-height: 25px;
    color: #1e293b;
}

/* 
@media only screen and (max-width: 1101px) {

    .insurance_top {
        margin-top: 49px;
    }
} */

@media only screen and (max-width: 600px) {

    .insurance_top {
        margin-top: 49px;
        height: auto;
        /* background-size: contain;
        background-repeat: round; */

    }

    .insurance_top_color {
        margin-top: 0px;

        padding-top: 75px;
        height: inherit;
        text-align: center;
    }

    .insurance_top_h1 {
        font-size: 36px;
        margin-bottom: 15px;

    }

    .insurance_top_div .home_link {
        font-size: 18px;
        margin-right: 4px;
    }


    /* ----------------Part 2------------------- */
    .bankAccount {
        margin: 37px 0 0;
        justify-content: center;
        min-width: 300px;
    }

    .bankAccount_h1 {
        margin: 30px auto 15px;
        font-size: 20px;

    }

    .bankAccount_h1_bottom {
        text-align: center;
        margin: 0px auto 15px;
        width: 70px;
    }

    /* -------------------- */

    .bankAccount_content {
        width: 100%;
        margin: 5px 0;
        padding: 0 25px;
    }

    .bankAccount_content>div {
        margin: 15px 0;
        padding: 15px 0;

    }


    .bankAccount_content>div>h2 {
        font-size: 21px;
        font-weight: 500;
        margin: 15px;
        color: #1e293b;
    }

    .bankAccount_content>div>h3 {
        font-size: 20px;
        font-weight: 400;
        margin: 10px;
    }

    .bankAccount_content>div>p {
        font-size: 16px;
        margin: 15px;
        line-height: 25px;
    }

}
.confidentialityWrapper {
    width: 100%;
}

.insurance_top {
    margin-top: -200px;
    background-image: url(/static/media/blog-header.ff93143c.jpg);
    height: auto;
    /* height: 85vh; */
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position-y: left;
    background-size: cover;
}

.insurance_top_color {
    margin-top: 0px;
    padding-top: 350px;
    padding-bottom: 90px;
    background-color: rgba(0, 0, 255, 0.285);
    height: inherit;
    text-align: center;
}

.insurance_top_h1 {
    font-size: 56px;
    color: #ffffff;
    margin-bottom: 45px;

}

.insurance_top_div {
    display: flex;
    justify-content: center;
}


.insurance_top_div p {
    color: rgb(99, 198, 237);

}

.insurance_top_div .home_link {
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    text-decoration: none;
    margin-right: 4px;
}

.insurance_top_div>.home_link:hover {
    text-decoration: underline;
}


/* ----------------Part 2------------------- */
.confidentiality {
    margin: 77px 0 0;
    justify-content: center;
    min-width: 300px;
}

.confidentiality_h1 {
    margin: 80px auto 15px;
    font-size: 32px;
    text-align: center;

}

.confidentiality_h1_bottom {
    text-align: center;
    margin: 0px auto 65px;
    border-bottom: 4px solid rgb(62, 121, 232);
    width: 70px;
}


/* -------------------Part 3--------------------- */
.confidentiality_content {
    width: 100%;
    margin: 25px 0;
    padding: 0 105px;
}

.confidentiality_content>div {
    margin: 25px 0;
    padding: 55px 0;
    border-bottom: 1px solid transparent;
    border-color: #cbd5e1;
}

.confidentiality_content>div>h2 {
    font-size: 27px;
    font-weight: 500;
    margin: 20px;
    color: #1e293b;
}

.confidentiality_content>div>h2:hover {
    color: rgba(0, 0, 255, 0.705);
    transition: 300ms;
    cursor: pointer;
}

.confidentiality_content>div>h3 {
    font-size: 20px;
    font-weight: 400;
    margin: 10px;
}

.confidentiality_content>div>p {
    font-size: 18px;
    margin: 15px;
    line-height: 25px;
    color: #1e293b;
}

.confidentiality_content>div>ul>li {
    font-size: 18px;
    margin: 15px;
    line-height: 25px;
    color: #1e293b;
}

/* 
@media only screen and (max-width: 1101px) {

    .insurance_top {
        margin-top: 49px;
    }
} */

@media only screen and (max-width: 600px) {

    .insurance_top {
        margin-top: 49px;
        height: auto;
        /* background-size: contain;
        background-repeat: round; */

    }

    .insurance_top_color {
        margin-top: 0px;

        padding-top: 75px;
        height: inherit;
        text-align: center;
    }

    .insurance_top_h1 {
        font-size: 36px;
        margin-bottom: 15px;

    }

    .insurance_top_div .home_link {
        font-size: 18px;
        margin-right: 4px;
    }


    /* ----------------Part 2------------------- */
    .confidentiality {
        margin: 37px 0 0;
        justify-content: center;
        min-width: 300px;
    }

    .confidentiality_h1 {
        margin: 30px auto 15px;
        font-size: 20px;

    }

    .confidentiality_h1_bottom {
        text-align: center;
        margin: 0px auto 15px;
        width: 70px;
    }

    /* -------------------- */

    .confidentiality_content {
        width: 100%;
        margin: 5px 0;
        padding: 0 25px;
    }

    .confidentiality_content>div {
        margin: 15px 0;
        padding: 15px 0;

    }


    .confidentiality_content>div>h2 {
        font-size: 21px;
        font-weight: 500;
        margin: 15px;
        color: #1e293b;
    }

    .confidentiality_content>div>h3 {
        font-size: 20px;
        font-weight: 400;
        margin: 10px;
    }

    .confidentiality_content>div>p {
        font-size: 16px;
        margin: 15px;
        line-height: 25px;
    }

}
.legalNoticeWrapper {
    width: 100%;
}

.insurance_top {
    margin-top: -200px;
    background-image: url(/static/media/blog-header.ff93143c.jpg);
    height: auto;
    /* height: 85vh; */
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position-y: left;
    background-size: cover;
}

.insurance_top_color {
    margin-top: 0px;
    padding-top: 350px;
    padding-bottom: 90px;
    background-color: rgba(0, 0, 255, 0.285);
    height: inherit;
    background-repeat: round;
    text-align: center;
}

.insurance_top_h1 {
    font-size: 56px;
    color: #ffffff;
    margin-bottom: 45px;

}

.insurance_top_div {
    display: flex;
    justify-content: center;
}


.insurance_top_div p {
    color: rgb(99, 198, 237);

}

.insurance_top_div .home_link {
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    text-decoration: none;
    margin-right: 4px;
}

.insurance_top_div>.home_link:hover {
    text-decoration: underline;
}


/* ----------------Part 2------------------- */
.legalNotice {
    margin: 77px 0 0;
    justify-content: center;
    min-width: 300px;
}

.legalNotice_h1 {
    margin: 80px auto 15px;
    font-size: 32px;
    text-align: center;

}

.legalNotice_h1_bottom {
    text-align: center;
    margin: 0px auto 65px;
    border-bottom: 4px solid rgb(62, 121, 232);
    width: 70px;
}


/* -------------------Part 3--------------------- */
.legalNotice_content {
    width: 100%;
    margin: 25px 0;
    padding: 0 105px;
}

.legalNotice_content>div {
    margin: 25px 0;
    padding: 55px 0;
    border-bottom: 1px solid transparent;
    border-color: #cbd5e1;
}

.legalNotice_content>div>h2 {
    font-size: 27px;
    font-weight: 500;
    margin: 20px;
    color: #1e293b;
}

.legalNotice_content>div>h2:hover {
    color: rgba(0, 0, 255, 0.705);
    transition: 300ms;
    cursor: pointer;
}

.legalNotice_content>div>h3 {
    font-size: 20px;
    font-weight: 400;
    margin: 10px;
}

.legalNotice_content>div>p {
    font-size: 18px;
    margin: 15px;
    line-height: 25px;
    color: #1e293b;
}

/* @media only screen and (max-width: 1101px) {

    .insurance_top {
        margin-top: 49px;
    }
} */

@media only screen and (max-width: 600px) {

    .insurance_top {
        margin-top: 49px;
        height: auto;
        /* background-size: contain;
        background-repeat: round; */

    }

    .insurance_top_color {
        margin-top: 0px;
        padding-top: 75px;
        height: inherit;
        text-align: center;
    }

    .insurance_top_h1 {
        font-size: 36px;
        margin-bottom: 15px;

    }

    .insurance_top_div .home_link {
        font-size: 18px;
        margin-right: 4px;
    }


    /* ----------------Part 2------------------- */
    .legalNotice {
        margin: 37px 0 0;
        justify-content: center;
        min-width: 300px;
    }

    .legalNotice_h1 {
        margin: 30px auto 15px;
        font-size: 20px;

    }

    .legalNotice_h1_bottom {
        text-align: center;
        margin: 0px auto 15px;
        width: 70px;
    }

    /* -------------------- */

    .legalNotice_content {
        width: 100%;
        margin: 5px 0;
        padding: 0 25px;
    }

    .legalNotice_content>div {
        margin: 15px 0;
        padding: 15px 0;

    }


    .legalNotice_content>div>h2 {
        font-size: 21px;
        font-weight: 500;
        margin: 15px;
        color: #1e293b;
    }

    .legalNotice_content>div>h3 {
        font-size: 20px;
        font-weight: 400;
        margin: 10px;
    }

    .legalNotice_content>div>p {
        font-size: 16px;
        margin: 15px;
        line-height: 25px;
    }

}
.signupContainer {
    width: 100%;
    margin: 64px auto 0px;
    background-image: url(/static/media/login.c6b50ff1.avif);

}

.signup_sub_container {
    display: flex;
    justify-content: space-around;
    padding: 100px 50px;
    margin: 0 auto;
    background-color: rgba(98, 98, 220, 0.706);

    /* width: 100%; */
    width: inherit;
}

.signup_left {
    width: 40%;
    /* max-width: 40%; */
    padding: 135px 0 0 105px;
}

.signup-title-1 {
    font-size: 48px;
    font-weight: 600;
    color: #f4f1f1;
}

.signup-title-2 {
    font-size: 48px;
    font-weight: 600;
    line-height: 55px;
    color: rgb(114, 204, 227);
    margin-bottom: 65px;
}

.signup-title-3 {
    font-size: 36px;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 10px;
}

.signup-title-message {
    font-size: 18px;
    color: antiquewhite;
    line-height: 20px;
    word-spacing: 2px;
}

.signup_form {
    width: 50%;
    /* width: 40%; */
    margin: 10px auto;
    padding: 25px 15px;
    background-color: #f8f9fa;
    border-radius: 5px;
}

form {
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
}

.form_content {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-width: 300px;
    margin: 10px auto 0;
    padding-bottom: 35px !important;
    border-bottom: 1px solid #94a3b8;

}

.signup_form>form>h1 {
    margin-bottom: 35px;
    font-family: 'Times New Roman', Times, serif;
    font-style: italic;
    font-size: 42px;
    font-weight: 700;
    text-align: center;
    color: rgba(43, 86, 226, 0.794);
    text-decoration: underline;
}

.signup_form>form>div {

    margin-bottom: 15px;
    padding: 0px 10px;

}

.signup_form>form label {
    font-size: 18px;

}

.signup_form>form input:not([type=reset]),
select {
    display: block;
    margin-bottom: 15px;
    margin-top: 5px;
    padding: 5px 7px;
    font-size: 16px;
    border: 1px solid rgba(139, 139, 139, 0.454);
    border-radius: 5px;
    accent-color: rgba(0, 0, 255, 0.656) !important;
    margin-left: 0;
    margin-right: 0;
    width: 210px;
    min-width: 100px;

}

.signup_form>form select {
    /* width: 210px; */
    /* width: auto; */
}

.signup_form>form input:not([type=reset]):focus {
    border: 1px solid transparent;

}

.input_select {
    padding: 5px 7px;
    /* width: 230px !important; */
    font-size: 16px !important;
}

.btn_container {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 45px;
}

.cancel {
    padding: 7px 15px;
    color: #ffffff;
    background-color: rgba(255, 0, 0, 0.700);
    font-size: 18px;
    border: 1px solid transparent;
    border-radius: 5px;
}

.connect_me {
    text-decoration: none;
    padding: 7px 15px;
    color: #ffffff;
    background-color: rgba(0, 0, 255, 0.700);
    font-size: 18px;
    border: 1px solid transparent;
    border-radius: 5px;

}

.login_link {
    margin: 40px auto 0;
    text-align: center;

}

.login_link>a {
    text-decoration: none;
    justify-content: center;
    color: blue;
}

@media only screen and (max-width: 1101px) {
    .signupContainer {
        padding: auto 25px;
        margin: 47px 0 0px;

    }

    .signup_sub_container {
        display: flex;
        flex-direction: row;
        padding: 10px 25px;
        margin: 0 auto;
        width: 100%;
    }



    .signup_left {
        width: 50%;
        /* max-width: 40%; */
        padding: 135px 0 0 105px;
    }

    .signup-title-1 {
        font-size: 48px;
        font-weight: 600;
        color: #f4f1f1;
    }

    .signup-title-2 {
        font-size: 48px;
        font-weight: 600;
        line-height: 55px;
        color: rgb(114, 204, 227);
        margin-bottom: 65px;
    }

    .signup-title-3 {
        font-size: 36px;
        color: #ffffff;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .signup-title-message {
        font-size: 18px;
        color: antiquewhite;
        line-height: 20px;
        word-spacing: 2px;
    }

    .signup_form {
        width: 40%;
        margin: 10px 0 10px;
        padding: 25px 15px;
        background-color: #f8f9fa;
        border-radius: 5px;
    }

    form {
        box-sizing: border-box;
        margin: 0 auto;
        width: 100%;
    }

    .form_content {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 10px auto 0;
        padding-bottom: 35px !important;
        border-bottom: 1px solid #94a3b8;
    }


    .signup_form>form>h1 {
        margin-bottom: 25px;
        font-size: 36px;
    }

    .signup_form>form>div {
        margin-bottom: 15px;
    }

    .signup_form>form label {
        font-size: 16px;
    }

    .signup_form>form input:not([type=reset]),
    select {
        margin-top: 10px;
        padding: 5px 7px;
        width: 100%;
        font-size: 14px;
    }

    .signup_form>form select {
        width: 100% !important;
    }
}

@media only screen and (max-width: 800px) {

    .signupContainer {
        padding: auto 25px;
        margin: 47px 0 0px;
        /* background-size: contain; */
        /* background-repeat: round; */

    }

    .signup_sub_container {
        display: flex;
        flex-direction: column;
        padding: 10px 25px;
        margin: 0 auto;
        width: 100%;
    }

    .signup_left {
        text-align: center;
        width: 100% !important;
        padding: 15px 0;
        margin: 0 auto;
    }

    .signup-title-1 {
        font-size: 24px;
        font-weight: 600;
    }

    .signup-title-2 {
        font-size: 22px;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 25px;
    }

    .signup-title-3 {
        font-size: 21px;
        margin-bottom: 10px;
    }

    .signup-title-message {
        font-size: 16px;
        line-height: 18px;
        word-spacing: 2px;
        width: 100% !important;
    }

    .signup_form {
        width: 100%;
        margin: 10px 0 10px;
        padding: 25px 15px;
        background-color: #f8f9fa;
        border-radius: 5px;
    }

    form {
        box-sizing: border-box;
        margin: 0 auto;
        width: 100%;
    }

    .form_content {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 10px auto 0;
        padding-bottom: 35px !important;
        border-bottom: 1px solid #94a3b8;
    }


    .signup_form>form>h1 {
        margin-bottom: 25px;
        font-size: 36px;
    }

    .signup_form>form>div {
        margin-bottom: 15px;
    }

    .signup_form>form label {
        font-size: 16px;
    }

    .signup_form>form input:not([type=reset]),
    select {
        margin-top: 10px;
        padding: 5px 7px;
        width: 100%;
        font-size: 14px;
    }

    .signup_form>form select {
        width: 100% !important;
    }

    .signup_form>form input:not([type=reset]):focus {
        border: 1px solid transparent;
    }

    .btn_container {
        display: flex;
        justify-content: space-between;
        margin: 15px 0 45px;
    }

    .cancel {
        padding: 5px 10px;
        font-size: 16px;

    }

    .connect_me {
        padding: 5px 10px;
        font-size: 16px;
    }

    .login_link {
        margin: 40px auto 0;
    }

}
.loginContainer {
    margin: 64px auto 0px;
    background-image: url(/static/media/login.c6b50ff1.avif);
}

.login_sub_container {
    display: flex;
    justify-content: space-around;
    padding: 100px 50px;
    margin: 0 auto;
    background-color: rgba(98, 98, 220, 0.706);
    height: inherit;
    width: inherit;
}

.login_left {
    /* width: 575px; */
    width: 50%;
    padding: 100px 0 0 105px;
}

.login-title-1 {
    font-size: 48px;
    font-weight: 600;
    color: #f4f1f1;
}

.login-title-2 {
    font-size: 48px;
    font-weight: 600;
    line-height: 55px;
    color: rgb(114, 204, 227);
    margin-bottom: 65px;
}

.login-title-3 {
    font-size: 36px;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 10px;
}

.login-title-message {
    font-size: 18px;
    color: antiquewhite;
    line-height: 20px;
    word-spacing: 2px;
}

.login_form {
    /* width: 400px; */
    width: 40%;
    margin: 10px auto;
    padding: 25px 45px;
    background-color: #f8f9fa;
    border-radius: 5px;
}

.login_form>h1 {
    margin-bottom: 35px;
    font-family: 'Times New Roman', Times, serif;
    font-style: italic;
    font-size: 42px;
    font-weight: 700;
    text-align: center;
    color: rgba(43, 86, 226, 0.794);
    text-decoration: underline;
}

.login_form>div {
    margin-bottom: 25px;
}

.login_form>div>label {
    font-size: 18px;
}

.login_form>div>input:not([type=checkbox]) {
    display: block;
    margin-top: 15px;
    padding: 10px 7px;
    width: 100%;
    font-size: 16px;
    border: 1px solid rgba(139, 139, 139, 0.454);
    border-radius: 5px;
    accent-color: rgba(0, 0, 255, 0.656);
    /* accent-color: #ffffff; */
}

.login_form>div>input:not([type=checkbox]):focus {
    border: 1px solid transparent;
}

.login_btn {
    display: block;
    margin: 10px auto 30px;
    border: 1px solid transparent;
    border-color: blue;
    border-radius: 7px;
    text-decoration: none;
    color: #ffffff;
    background-color: rgba(0, 0, 255, 0.656);
    padding: 10px;
    font-size: 16px;
    transition: transform 200ms;
    text-align: center;
    width: 100%;
}

.forgot_password {
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 30px;
}

.forgot_password>a {
    text-decoration: none;
    color: blue;
}

@media only screen and (max-width: 1101px) {

    .loginContainer {
        padding: auto 25px;
        margin: 47px 0 0px;


    }
}

@media only screen and (max-width: 600px) {

    .loginContainer {
        padding: auto 25px;
        margin: 47px 0 0px;
        background-size: contain;
        background-repeat: round;

    }

    .login_sub_container {
        display: flex;
        flex-direction: column;
        padding: 10px 25px;
        margin: 0 auto;
    }

    .login_left {
        text-align: center;
        width: 100%;
        padding: 15px 0;
    }

    .login-title-1 {
        font-size: 24px;
        font-weight: 600;
        color: #f4f1f1;
    }

    .login-title-2 {
        font-size: 22px;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 25px;
    }

    .login-title-3 {
        font-size: 21px;
        margin-bottom: 10px;
    }

    .login-title-message {
        font-size: 16px;
        line-height: 17px;
        word-spacing: 2px;
    }

    .login_form {
        width: 100%;
        margin: 10px 0 10px;
        padding: 25px 15px;
        background-color: #f8f9fa;
        border-radius: 5px;
    }

    .login_form>h1 {
        margin-bottom: 25px;
        font-size: 36px;

    }

    .login_form>div {
        margin-bottom: 15px;
    }

    .login_form>div>label {
        font-size: 16px;
    }

    .login_form>div>input:not([type=checkbox]) {
        margin-top: 10px;
        padding: 5px 7px;
        width: 100%;
        font-size: 16px;
    }


    .login_btn {
        display: block;
        margin: 10px auto 30px;
        padding: 10px;
        font-size: 16px;
        width: 100%;
    }

    .forgot_password {
        line-height: 25px;
    }

}
.emailVerify_container {
    width: 100%;
    margin: 75px auto 0px;
    background-image: url(/static/media/login.c6b50ff1.avif);


    /* height: 100vh; */
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    justify-content: center;
}

.emailVerify_sub_container {
    display: flex;
    justify-content: space-around;
    padding: 20px 50px;
    margin: 0 0px;
    background-color: rgba(98, 98, 220, 0.706);

    width: 100%;
}

.emailVerify_left {
    width: 40%;
    padding: 55px 0 0 70px;
}

.emailVerify-title-1 {
    font-size: 48px;
    font-weight: 600;
    color: #f4f1f1;
}

.emailVerify-title-2 {
    font-size: 48px;
    font-weight: 600;
    line-height: 55px;
    color: rgb(114, 204, 227);
    margin-bottom: 65px;
}

.emailVerify-title-3 {
    font-size: 36px;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 10px;
}

.emailVerify-title-message {
    font-size: 18px;
    color: antiquewhite;
    line-height: 20px;
    word-spacing: 2px;
}

.message_display {
    background-color: #ffffff;
    /* width: 40%; */
    width: 400px;
    text-align: center;
    padding: 50px 50px 0 !important;
    margin: 20px auto;
    height: 50vh;
    margin: 105px 0 0px;
    border-radius: 5px;

}

.message_display h1 {
    font-size: 32px;
    text-decoration: underline;
    margin: 20px auto 50px;
    color: #1b1b82;
}

.message_display .link {
    width: 100%;
    color: #ffffff;
    background-color: #3333f4d1;
    padding: 10px 15px;
    margin: 10px auto 0;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
}

@media only screen and (max-width: 600px) {}

/* 'sm': '640px', */
@media (max-width: 640px) {
    .emailVerify_container {
        /* height: 50vh; */
        background-repeat: repeat-y;
        padding: auto 25px;
        margin: 47px 0 0px;
        background-size: contain;
        background-repeat: round;

    }

    .emailVerify_sub_container {
        display: flex;
        flex-direction: column;
        padding: 10px 25px 25px;
        margin: 0 auto;
    }

    .emailVerify_left {
        text-align: center;
        width: 100%;
        padding: 15px 0;
    }

    .emailVerify-title-1 {
        font-size: 24px;
        font-weight: 600;
    }

    .emailVerify-title-2 {
        font-size: 22px;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 25px;
    }

    .emailVerify-title-3 {
        font-size: 21px;
        margin-bottom: 10px;
    }

    .emailVerify-title-message {
        font-size: 16px;
        line-height: 18px;
        word-spacing: 2px;
    }



    .message_display {
        background-color: #ffffff;
        width: 100%;
        /* min-width: 400px; */
        text-align: center;
        padding: 15px 15px;
        /* margin: 20px auto; */
        /* max-height: 1000vh !important; */
        height: 25vh;
        margin: 35px 0 0px;
        border-radius: 5px;
    }

    .message_display h1 {
        font-size: 18px;
        text-decoration: underline;
        margin: 5px 0 30px;
    }

    .message_display .link {
        width: 100%;
        font-size: 14px;
        background-color: #3333f4d1;
        padding: 10px 15px;
        margin: 10px 0 10px;
    }
}

/* 'md': '768px', */
@media (min-width: 768px) {}

/* 'lg': '1024px', */
/* // => @media (min-width: 1024px) { ... } */

/* 'xl': '1280px', */
/* // => @media (min-width: 1280px) { ... } */

/* '2xl': '1536px', */
/* // => @media (min-width: 1536px) { ... } */
.border {
    border: 1px solid transparent;
    border-color: #e5e7eb;
    margin: 15px;
}

.profil_info {
    display: flex;
    justify-content: space-between;
    margin: 10px 150px;

}

.profil_info .profil_content_1 {
    width: 30%;
    text-align: center;
}

.profil_content_1 .boder {
    text-align: center;
    position: fixed;
}

.profil_info .profil_content_2 {
    width: 65%;
    padding: 15px 35px;

}

.profil_info .border h2 {
    margin-top: 15px;
}

.profil_info .border .idPicture {
    margin: 0;
    padding: 0;
}

.profil_info .border img {
    height: 30vh;
    width: 100%;
}

.profil_info .picture {
    height: 150px;
    width: 150px;
    background-color: #9f1239;
    border: 1px solid transparent;
    border-radius: 100%;
    text-align: center;
    position: relative;
    /* top: 50%;
    left: 50%; */
    translate: 40%;
    margin: 25px 0;

}

.profil_info .picture h1 {
    color: #ffffff;
    font-weight: 600;
    font-size: 48px;
    position: relative;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
}

/* ***************part 2******** */
.profil_content_2 .profil_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
}

.profil_content_2>.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    font-size: 16px;
}

.profil_content_2>.row>.label {
    color: #334155;
    font-weight: 300;
    font-size: 18px;

}

/* *************security part *********/
.profil_content_3 {
    margin: 10px 150px;
    padding: 15px 35px;
}

.profil_content_3>.profil_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
}

.profil_content_3>.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    font-size: 16px;
    text-align: center;
    margin: 0 auto;
    width: 200px;
}

.profil_content_3>.row>.label {
    color: #334155;
    font-weight: 300;
    font-size: 18px;

}

@media (max-width: 1201px) {
    .profil_info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 10px 20px;
        padding: 15px 0px;
    }

    .profil_info .profil_content_1 {
        width: auto;
        margin: 0 15px;
        text-align: center;
    }

    .profil_content_1 .boder {
        text-align: center;
        position: fixed;
    }

    .profil_info .profil_content_2 {
        width: auto;
        margin: 0 15px;
        padding: 10px 15px;

    }

    .profil_info .border h2 {
        margin-top: 15px;
        font-size: 18px;
    }

    .profil_info .picture {
        height: 150px;
        width: 150px;
        background-color: #9f1239;
        border: 1px solid transparent;
        border-radius: 100%;
        text-align: center;
        position: relative;
        /* top: 50%;
        left: 50%; */
        translate: 40%;
        margin: 25px 0;

    }

    .profil_info .picture h1 {
        color: #ffffff;
        font-weight: 600;
        font-size: 48px;
        position: relative;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
    }

    /* ***************part 2******** */
    .profil_content_2 .profil_title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 35px;
    }

    .profil_content_2>.row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 18px;
        font-size: 16px;
    }

    .profil_content_2>.row>.label {
        color: #334155;
        font-weight: 300;
        font-size: 14px;
        /* min-width: 115px; */

    }

    .profil_content_2>.row>.label_value {
        color: #1e293b;
        /* font-weight: 500; */
        font-size: 14px;

    }

    /* *************security part *********/
    .profil_content_3 {
        margin: 10px 20px;
        padding: 15px 25px;
    }

    .profil_content_3>.profil_title {
        margin-bottom: 25px;
        font-size: 14px;
    }

    .profil_content_3>.row {
        margin-bottom: 15px;
        font-size: 16px;
        text-align: center;
        margin: 0 auto;
        width: 200px;
    }

    .profil_content_3>.row>.label {
        color: #334155;
        font-weight: 300;
        font-size: 18px;

    }
}

.user_dashbord {
    margin-top: 77px;
}

.info {
    display: flex;
}

.info_title {
    background-color: blueviolet;
    color: #ffffff;
    font-size: 25px;
    font-weight: 500;
    padding: 20px 20px;
}

.marquee {
    color: #ffffff;
    background-color: #4ade80;
    font-size: 18px;
    /* justify-content: center; */
    padding: 7px 0;
    margin-top: 18px;
}

@media (min-width: 700px) {
    .whyChooseUs {
        margin-top: -50px !important;
    }
}
.myaccount {
    margin: 100px 0 10px;
    text-align: center;
}

.myaccount>ion-icon {
    font-size: 55px;
    color: #1e40af;
}

.myaccount .items {
    margin: 25px 0 50px;
    display: flex;
    justify-content: space-around;
}

.myaccount>.items>.link {
    font-size: 24px;
    color: #6b7280;


}

.myaccount>.items>.link:focus {
    border-bottom: 3px solid #1e40af;
    color: #0a0a0ad8;
    padding-bottom: 10px;
    text-decoration: none;
    width: 25%;
}
.data_container {
    margin: 0 auto;
    padding: 15px 25px;
    width: 600px;
    border: 1px solid #9ca3af;
    border-radius: 5px;
    background-color: #cbd5e1;
}

.datas {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;

}

.datas>h2 {
    min-width: 90px;
    margin: 10px 0;
}

.datas>p {
    font-size: 20px;
    margin: 10px 0;

}

@media (max-width: 768px) {
    .data_container {
        margin: 0 auto;
        padding: 15px 10px;
        width: 90%;
    }
}
.myaccount_container {}

.myaccount_container>h1 {
    margin-bottom: 50px;
    font-size: 48px;
    font-weight: 400;
    text-align: center;
}

.myaccount_container>.actions {
    display: flex;
    justify-content: space-around;
}

.myaccount_container>.actions>.btn_op {
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 15px 25px;
    text-decoration: none;
}

.myaccount_container>.actions>.btn_op>.link {
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
    text-decoration: none;
}

.myaccount_container>.actions .payment_btn {
    background-color: #14532d;
}

.myaccount_container>.actions .withdrawal_btn {
    background-color: #7f1d1d;
    color: #ffffff;

}
textarea:invalid input:invalid {
    border: 2px dashed red;
}

textarea:valid input:valid {
    border: 2px solid lime;
}

.loanFormWrapper {
    margin-top: 77px;

}

.loanFormWrapper>h1 {
    color: #1e3a8a;
    text-align: center;
    padding: 25px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #f3f3f3;
    /* border-bottom: 1px solid #94a3b8; */
}

.loanFormWrapper>h1:hover {
    color: #2459bc;
}

.loanFormWrapper>.loan_info {
    font-size: 20px;
    line-height: 35px;
    text-align: center;
}

.loanFormWrapper>.loan_info>.alert {
    color: #3b82f6;
}

.fromForLoan {
    width: 600px;
    margin: 45px auto;
}

.loan_form>div {
    display: block;
    margin: 25px auto;
}

.loan_form>div>label {
    display: block;
    font-size: 18px;
    margin: 10px auto;
}

.loan_form>div>input,
.loan_form>div>textarea {
    display: block;
    width: 100%;
}

.loan_form>div>input {
    font-size: 18px;
    height: 35px;
    padding: 0 20px;
    accent-color: #2459bc;
    border: 1px solid #d1d5dba3;
    border-radius: 3px;
}

.loan_form>div>input:focus {
    border: 0px solid transparent;
}




.loan_btn {
    padding-top: 35px;
    border-top: 1px solid #f3f3f3;

}

.loan_btn>.cancel {
    width: 100px;
}

@media only screen and (max-width: 600px) {

    .loanFormWrapper {
        margin: 77px 15px 10px;

    }

    .loanFormWrapper>h1 {
        font-size: 30px;
        text-align: center;
        padding: 0 0 15px;
        margin-bottom: 10px;
        border-bottom: 1px solid #f3f3f3;
        /* border-bottom: 1px solid #94a3b8; */
    }

    .fromForLoan {
        width: auto;
        margin: 45px 15px;
    }

    .loan_form>div {
        display: block;
        margin: 25px auto;
    }

    .loan_form>div>label {
        display: block;
        font-size: 18px;
        margin: 10px auto;
    }

    .loan_form>div>input,
    .loan_form>div>textarea {
        display: block;
        margin: auto 0;
        width: 100%;
    }
}
.users_wrapper {
    margin: 77px auto;
    text-align: center;
}

.users_wrapper h2 {
    border-bottom: 1px solid #d4d4d8;
    font-size: 27px;
    margin: 15px auto;
    padding: 15px;
    text-align: center;
}

.users_wrapper .link {
    color: #1d4ed8;
}

.users_item {
    display: flex;
    font-size: 18px;

    justify-content: space-between;
    margin: 15px auto;
    padding: 0 15px;
    width: 600px;
}


.modal-content {
    background-color: #2196F3;
    /* Bleu */
    padding: 20px;
    border-radius: 10px;
    width: 50%;
    max-width: 400px;
}


/**
  * Gestion des popups
  */

.popupBackground {
    display: flex;
    position: fixed;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    /* width: 100%;
    height: 100%; */
    background-color: rgba(0, 0, 0, 0.5);
}

.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 80%;
    max-width: 500px;
    padding: 20px;
    text-align: center;
    color: white;
    font-size: 17px;
    font-weight: 500;
    background-color: #2196F3;
    border-radius: 10px;
    z-index: 1;

}

.close {
    color: #ffffff;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
}

.popup h2 {
    width: 100%;
    margin-bottom: 20px;
}

.popup div {
    font-size: 25px;
    padding: 5px;
    padding-bottom: 15px;
}

.popup_form {
    display: flex;
    flex-direction: column;
}

.popup_form>label {
    text-align: center;
    margin: 10px auto;
}

/* Eléments de formulaire */
.popup_form>input {
    padding: 15px 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: none;
    /* width: calc(100% - 20px); */
    box-sizing: border-box;

}

.popup_btn {
    padding: 10px 20px;
    background-color: #ffffff;
    color: #2196F3;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* max-width: 360px; */

}

.popup_btn:hover {
    background-color: #f0f0f0;
}


@media (max-width: 768px) {
    .users_wrapper>h2 {
        border-bottom: 1px solid #d4d4d8;
        font-size: 18px;
        margin: 15px auto;
        /* padding: 15px; */
    }

    .users_item {
        display: flex;
        justify-content: space-between;
        margin: 15px 0;
        padding: 0 15px;
        width: auto;

    }

    .users_wrapper .link_container {
        flex-wrap: wrap;
        width: 50%;

    }


    .popup {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 80%;
        max-width: 400px;
        padding: 20px;
        text-align: center;
        color: white;
        font-size: 17px;
        font-weight: 500;
        background-color: #2196F3;
        border-radius: 10px;
        z-index: 1;
    }
}
* {
    box-sizing: border-box;
    color: rgb(43, 24, 13);
    margin: 0;
    padding: 0;
    font-weight: 400;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
    font-family: Georgia, 'Times New Roman', Times, serif;


    /* min-height: 100%; */
    /* position: relative; */
    /* text-align: start; */
    /* top: 0; */

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
