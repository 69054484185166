.signupContainer {
    width: 100%;
    margin: 64px auto 0px;
    background-image: url("../../assets/login/login.avif");

}

.signup_sub_container {
    display: flex;
    justify-content: space-around;
    padding: 100px 50px;
    margin: 0 auto;
    background-color: rgba(98, 98, 220, 0.706);

    /* width: 100%; */
    width: inherit;
}

.signup_left {
    width: 40%;
    /* max-width: 40%; */
    padding: 135px 0 0 105px;
}

.signup-title-1 {
    font-size: 48px;
    font-weight: 600;
    color: #f4f1f1;
}

.signup-title-2 {
    font-size: 48px;
    font-weight: 600;
    line-height: 55px;
    color: rgb(114, 204, 227);
    margin-bottom: 65px;
}

.signup-title-3 {
    font-size: 36px;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 10px;
}

.signup-title-message {
    font-size: 18px;
    color: antiquewhite;
    line-height: 20px;
    word-spacing: 2px;
}

.signup_form {
    width: 50%;
    /* width: 40%; */
    margin: 10px auto;
    padding: 25px 15px;
    background-color: #f8f9fa;
    border-radius: 5px;
}

form {
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
}

.form_content {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-width: 300px;
    margin: 10px auto 0;
    padding-bottom: 35px !important;
    border-bottom: 1px solid #94a3b8;

}

.signup_form>form>h1 {
    margin-bottom: 35px;
    font-family: 'Times New Roman', Times, serif;
    font-style: italic;
    font-size: 42px;
    font-weight: 700;
    text-align: center;
    color: rgba(43, 86, 226, 0.794);
    text-decoration: underline;
}

.signup_form>form>div {

    margin-bottom: 15px;
    padding: 0px 10px;

}

.signup_form>form label {
    font-size: 18px;

}

.signup_form>form input:not([type=reset]),
select {
    display: block;
    margin-bottom: 15px;
    margin-top: 5px;
    padding: 5px 7px;
    font-size: 16px;
    border: 1px solid rgba(139, 139, 139, 0.454);
    border-radius: 5px;
    accent-color: rgba(0, 0, 255, 0.656) !important;
    margin-left: 0;
    margin-right: 0;
    width: 210px;
    min-width: 100px;

}

.signup_form>form select {
    /* width: 210px; */
    /* width: auto; */
}

.signup_form>form input:not([type=reset]):focus {
    border: 1px solid transparent;

}

.input_select {
    padding: 5px 7px;
    /* width: 230px !important; */
    font-size: 16px !important;
}

.btn_container {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 45px;
}

.cancel {
    padding: 7px 15px;
    color: #ffffff;
    background-color: rgba(255, 0, 0, 0.700);
    font-size: 18px;
    border: 1px solid transparent;
    border-radius: 5px;
}

.connect_me {
    text-decoration: none;
    padding: 7px 15px;
    color: #ffffff;
    background-color: rgba(0, 0, 255, 0.700);
    font-size: 18px;
    border: 1px solid transparent;
    border-radius: 5px;

}

.login_link {
    margin: 40px auto 0;
    text-align: center;

}

.login_link>a {
    text-decoration: none;
    justify-content: center;
    color: blue;
}

@media only screen and (max-width: 1101px) {
    .signupContainer {
        padding: auto 25px;
        margin: 47px 0 0px;

    }

    .signup_sub_container {
        display: flex;
        flex-direction: row;
        padding: 10px 25px;
        margin: 0 auto;
        width: 100%;
    }



    .signup_left {
        width: 50%;
        /* max-width: 40%; */
        padding: 135px 0 0 105px;
    }

    .signup-title-1 {
        font-size: 48px;
        font-weight: 600;
        color: #f4f1f1;
    }

    .signup-title-2 {
        font-size: 48px;
        font-weight: 600;
        line-height: 55px;
        color: rgb(114, 204, 227);
        margin-bottom: 65px;
    }

    .signup-title-3 {
        font-size: 36px;
        color: #ffffff;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .signup-title-message {
        font-size: 18px;
        color: antiquewhite;
        line-height: 20px;
        word-spacing: 2px;
    }

    .signup_form {
        width: 40%;
        margin: 10px 0 10px;
        padding: 25px 15px;
        background-color: #f8f9fa;
        border-radius: 5px;
    }

    form {
        box-sizing: border-box;
        margin: 0 auto;
        width: 100%;
    }

    .form_content {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 10px auto 0;
        padding-bottom: 35px !important;
        border-bottom: 1px solid #94a3b8;
    }


    .signup_form>form>h1 {
        margin-bottom: 25px;
        font-size: 36px;
    }

    .signup_form>form>div {
        margin-bottom: 15px;
    }

    .signup_form>form label {
        font-size: 16px;
    }

    .signup_form>form input:not([type=reset]),
    select {
        margin-top: 10px;
        padding: 5px 7px;
        width: 100%;
        font-size: 14px;
    }

    .signup_form>form select {
        width: 100% !important;
    }
}

@media only screen and (max-width: 800px) {

    .signupContainer {
        padding: auto 25px;
        margin: 47px 0 0px;
        /* background-size: contain; */
        /* background-repeat: round; */

    }

    .signup_sub_container {
        display: flex;
        flex-direction: column;
        padding: 10px 25px;
        margin: 0 auto;
        width: 100%;
    }

    .signup_left {
        text-align: center;
        width: 100% !important;
        padding: 15px 0;
        margin: 0 auto;
    }

    .signup-title-1 {
        font-size: 24px;
        font-weight: 600;
    }

    .signup-title-2 {
        font-size: 22px;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 25px;
    }

    .signup-title-3 {
        font-size: 21px;
        margin-bottom: 10px;
    }

    .signup-title-message {
        font-size: 16px;
        line-height: 18px;
        word-spacing: 2px;
        width: 100% !important;
    }

    .signup_form {
        width: 100%;
        margin: 10px 0 10px;
        padding: 25px 15px;
        background-color: #f8f9fa;
        border-radius: 5px;
    }

    form {
        box-sizing: border-box;
        margin: 0 auto;
        width: 100%;
    }

    .form_content {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 10px auto 0;
        padding-bottom: 35px !important;
        border-bottom: 1px solid #94a3b8;
    }


    .signup_form>form>h1 {
        margin-bottom: 25px;
        font-size: 36px;
    }

    .signup_form>form>div {
        margin-bottom: 15px;
    }

    .signup_form>form label {
        font-size: 16px;
    }

    .signup_form>form input:not([type=reset]),
    select {
        margin-top: 10px;
        padding: 5px 7px;
        width: 100%;
        font-size: 14px;
    }

    .signup_form>form select {
        width: 100% !important;
    }

    .signup_form>form input:not([type=reset]):focus {
        border: 1px solid transparent;
    }

    .btn_container {
        display: flex;
        justify-content: space-between;
        margin: 15px 0 45px;
    }

    .cancel {
        padding: 5px 10px;
        font-size: 16px;

    }

    .connect_me {
        padding: 5px 10px;
        font-size: 16px;
    }

    .login_link {
        margin: 40px auto 0;
    }

}