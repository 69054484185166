.contactWrapper {
    width: 100%;
}

.contact_top {
    margin-top: -200px;
    background-image: url("../../assets/contact/contact-header.jpg");
    height: auto;
    /* height: 85vh; */
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position-y: left;
    background-size: cover;
}

.contact_top_color {
    margin-top: 0px;
    padding-top: 350px;
    padding-bottom: 90px;
    background-color: rgba(0, 0, 255, 0.285);
    height: inherit;
    text-align: center;
}

.contact_top_h1 {
    font-size: 48px;
    color: #ffffff;
    margin-bottom: 45px;

}

.contact_top_div {
    display: flex;
    justify-content: center;
}


.contact_top_div p {
    color: rgb(99, 198, 237);

}

.contact_top_div .home_link {
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    text-decoration: none;
    margin-right: 4px;
}

.contact_top_div>.home_link:hover {
    text-decoration: underline;
}

.contact_bottom {
    margin: 97px auto;
    padding: 0 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.contact_bottom h1 {
    font-size: 28px;
    color: rgba(44, 44, 123, 0.839);
    margin-bottom: 12px;
}

.contact_bottom p {
    font-size: 16px;
    margin-bottom: 32px;
}

.contact_bottom_form {
    /* box-sizing: border-box; */
    display: flex;
    flex-direction: column;
    width: 500px;
}

.contact_bottom_form div {
    margin-bottom: 25px !important;
}

.contact_bottom_form input,
textarea {
    padding: auto;
    padding-left: 7px;
    font-size: 16px;
    border: 1px solid transparent;
    border-color: #d1d5dba3;
    border-radius: 5px;
}

.contact_bottom_form input {
    height: 35px;
}

.contact_bottom_form input:focus,
textarea:focus {
    border: 1px solid transparent;
    accent-color: #2459bc;
}

.contact_bottom_form textarea {
    height: 135px;
}

.contact_bottom_form_top {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
}

.contact_bottom_form_top>input {
    width: 45%;
}

.style_input_2 {
    width: 100%;
}

.contact_btn {
    margin: 10px auto;
    border: 1px solid transparent;
    border-color: blue;
    border-radius: 7px;
    text-decoration: none;
    color: #ffffff;
    background-color: rgba(0, 0, 255, 0.523);
    padding: 10px;
    font-size: 16px;
    transition: transform 200ms;
    text-align: center;
}

.contact_btn:hover {
    transform: scale(1.15);
}

.contactWrapper ion-icon {
    font-size: 48px;
    color: #2459bcab;
}

/* @media only screen and (max-width: 1101px) {

    .contact_top {
        margin-top: -200px;
    }

    .contact_top_color {
        padding-top: 300px;

    }
} */

@media only screen and (max-width: 600px) {

    .contact_top {
        margin-top: 49px;
        height: auto;
        /* background-size: contain;
        background-repeat: round; */

    }

    .contact_top_color {
        margin-top: 0px;
        padding-top: 75px;
        height: inherit;
        /* height: 28.5vh; */
        text-align: center;
    }

    .contact_top_h1 {
        font-size: 36px;
        margin-bottom: 15px;

    }

    .contact_top_div {
        display: flex;
        justify-content: center;
    }

    .contact_top_div .home_link {
        font-size: 18px;
        margin-right: 4px;
    }

    .contact_top_div>.home_link:hover {
        text-decoration: underline;
    }

    /* ------------------------------------ */
    .contact_bottom {
        margin: 37px auto;
        padding: 0 30px;
    }

    .contact_bottom h1 {
        font-size: 24px;
        margin-bottom: 12px;
    }

    .contact_bottom p {
        font-size: 14px;
        margin-bottom: 32px;
    }

    .contact_bottom_form {
        display: flex;
        flex-direction: column;
        /* width: 500px; */
    }

    .contact_bottom_form div {
        margin-bottom: 25px !important;
    }

    .contact_bottom_form input,
    textarea {
        padding: auto;
        padding-left: 7px;
        font-size: 16px;
        border-radius: 5px;
    }

    .contact_bottom_form input {
        height: 35px;
    }

    .contact_bottom_form input:focus,
    textarea:focus {
        border: 1px solid transparent;
        accent-color: #2459bc;
    }

    .contact_bottom_form textarea {
        height: 135px;
    }

    .contact_bottom_form_top {
        display: flex;
        flex-direction: column;

    }

    .contact_bottom_form_top input[type=text] {
        margin-bottom: 25px;

    }

    .contact_bottom_form_top>input {
        width: 100%;

    }

    .contact_btn {
        margin: 10px auto;
        border: 1px solid transparent;
        border-color: blue;
        border-radius: 7px;
        text-decoration: none;
        color: #ffffff;

        background-color: rgba(0, 0, 255, 0.523);
        padding: 10px;
        font-size: 16px;
        transition: transform 200ms;
        text-align: center;
    }


}