.myaccount_container {}

.myaccount_container>h1 {
    margin-bottom: 50px;
    font-size: 48px;
    font-weight: 400;
    text-align: center;
}

.myaccount_container>.actions {
    display: flex;
    justify-content: space-around;
}

.myaccount_container>.actions>.btn_op {
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 15px 25px;
    text-decoration: none;
}

.myaccount_container>.actions>.btn_op>.link {
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
    text-decoration: none;
}

.myaccount_container>.actions .payment_btn {
    background-color: #14532d;
}

.myaccount_container>.actions .withdrawal_btn {
    background-color: #7f1d1d;
    color: #ffffff;

}