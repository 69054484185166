.confidentialityWrapper {
    width: 100%;
}

.insurance_top {
    margin-top: -200px;
    background-image: url("../../assets/insurance/blog-header.jpg");
    height: auto;
    /* height: 85vh; */
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position-y: left;
    background-size: cover;
}

.insurance_top_color {
    margin-top: 0px;
    padding-top: 350px;
    padding-bottom: 90px;
    background-color: rgba(0, 0, 255, 0.285);
    height: inherit;
    text-align: center;
}

.insurance_top_h1 {
    font-size: 56px;
    color: #ffffff;
    margin-bottom: 45px;

}

.insurance_top_div {
    display: flex;
    justify-content: center;
}


.insurance_top_div p {
    color: rgb(99, 198, 237);

}

.insurance_top_div .home_link {
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    text-decoration: none;
    margin-right: 4px;
}

.insurance_top_div>.home_link:hover {
    text-decoration: underline;
}


/* ----------------Part 2------------------- */
.confidentiality {
    margin: 77px 0 0;
    justify-content: center;
    min-width: 300px;
}

.confidentiality_h1 {
    margin: 80px auto 15px;
    font-size: 32px;
    text-align: center;

}

.confidentiality_h1_bottom {
    text-align: center;
    margin: 0px auto 65px;
    border-bottom: 4px solid rgb(62, 121, 232);
    width: 70px;
}


/* -------------------Part 3--------------------- */
.confidentiality_content {
    width: 100%;
    margin: 25px 0;
    padding: 0 105px;
}

.confidentiality_content>div {
    margin: 25px 0;
    padding: 55px 0;
    border-bottom: 1px solid transparent;
    border-color: #cbd5e1;
}

.confidentiality_content>div>h2 {
    font-size: 27px;
    font-weight: 500;
    margin: 20px;
    color: #1e293b;
}

.confidentiality_content>div>h2:hover {
    color: rgba(0, 0, 255, 0.705);
    transition: 300ms;
    cursor: pointer;
}

.confidentiality_content>div>h3 {
    font-size: 20px;
    font-weight: 400;
    margin: 10px;
}

.confidentiality_content>div>p {
    font-size: 18px;
    margin: 15px;
    line-height: 25px;
    color: #1e293b;
}

.confidentiality_content>div>ul>li {
    font-size: 18px;
    margin: 15px;
    line-height: 25px;
    color: #1e293b;
}

/* 
@media only screen and (max-width: 1101px) {

    .insurance_top {
        margin-top: 49px;
    }
} */

@media only screen and (max-width: 600px) {

    .insurance_top {
        margin-top: 49px;
        height: auto;
        /* background-size: contain;
        background-repeat: round; */

    }

    .insurance_top_color {
        margin-top: 0px;

        padding-top: 75px;
        height: inherit;
        text-align: center;
    }

    .insurance_top_h1 {
        font-size: 36px;
        margin-bottom: 15px;

    }

    .insurance_top_div .home_link {
        font-size: 18px;
        margin-right: 4px;
    }


    /* ----------------Part 2------------------- */
    .confidentiality {
        margin: 37px 0 0;
        justify-content: center;
        min-width: 300px;
    }

    .confidentiality_h1 {
        margin: 30px auto 15px;
        font-size: 20px;

    }

    .confidentiality_h1_bottom {
        text-align: center;
        margin: 0px auto 15px;
        width: 70px;
    }

    /* -------------------- */

    .confidentiality_content {
        width: 100%;
        margin: 5px 0;
        padding: 0 25px;
    }

    .confidentiality_content>div {
        margin: 15px 0;
        padding: 15px 0;

    }


    .confidentiality_content>div>h2 {
        font-size: 21px;
        font-weight: 500;
        margin: 15px;
        color: #1e293b;
    }

    .confidentiality_content>div>h3 {
        font-size: 20px;
        font-weight: 400;
        margin: 10px;
    }

    .confidentiality_content>div>p {
        font-size: 16px;
        margin: 15px;
        line-height: 25px;
    }

}