.emailVerify_container {
    width: 100%;
    margin: 75px auto 0px;
    background-image: url("../../assets/login/login.avif");


    /* height: 100vh; */
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    justify-content: center;
}

.emailVerify_sub_container {
    display: flex;
    justify-content: space-around;
    padding: 20px 50px;
    margin: 0 0px;
    background-color: rgba(98, 98, 220, 0.706);

    width: 100%;
}

.emailVerify_left {
    width: 40%;
    padding: 55px 0 0 70px;
}

.emailVerify-title-1 {
    font-size: 48px;
    font-weight: 600;
    color: #f4f1f1;
}

.emailVerify-title-2 {
    font-size: 48px;
    font-weight: 600;
    line-height: 55px;
    color: rgb(114, 204, 227);
    margin-bottom: 65px;
}

.emailVerify-title-3 {
    font-size: 36px;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 10px;
}

.emailVerify-title-message {
    font-size: 18px;
    color: antiquewhite;
    line-height: 20px;
    word-spacing: 2px;
}

.message_display {
    background-color: #ffffff;
    /* width: 40%; */
    width: 400px;
    text-align: center;
    padding: 50px 50px 0 !important;
    margin: 20px auto;
    height: 50vh;
    margin: 105px 0 0px;
    border-radius: 5px;

}

.message_display h1 {
    font-size: 32px;
    text-decoration: underline;
    margin: 20px auto 50px;
    color: #1b1b82;
}

.message_display .link {
    width: 100%;
    color: #ffffff;
    background-color: #3333f4d1;
    padding: 10px 15px;
    margin: 10px auto 0;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
}

@media only screen and (max-width: 600px) {}

/* 'sm': '640px', */
@media (max-width: 640px) {
    .emailVerify_container {
        /* height: 50vh; */
        background-repeat: repeat-y;
        padding: auto 25px;
        margin: 47px 0 0px;
        background-size: contain;
        background-repeat: round;

    }

    .emailVerify_sub_container {
        display: flex;
        flex-direction: column;
        padding: 10px 25px 25px;
        margin: 0 auto;
    }

    .emailVerify_left {
        text-align: center;
        width: 100%;
        padding: 15px 0;
    }

    .emailVerify-title-1 {
        font-size: 24px;
        font-weight: 600;
    }

    .emailVerify-title-2 {
        font-size: 22px;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 25px;
    }

    .emailVerify-title-3 {
        font-size: 21px;
        margin-bottom: 10px;
    }

    .emailVerify-title-message {
        font-size: 16px;
        line-height: 18px;
        word-spacing: 2px;
    }



    .message_display {
        background-color: #ffffff;
        width: 100%;
        /* min-width: 400px; */
        text-align: center;
        padding: 15px 15px;
        /* margin: 20px auto; */
        /* max-height: 1000vh !important; */
        height: 25vh;
        margin: 35px 0 0px;
        border-radius: 5px;
    }

    .message_display h1 {
        font-size: 18px;
        text-decoration: underline;
        margin: 5px 0 30px;
    }

    .message_display .link {
        width: 100%;
        font-size: 14px;
        background-color: #3333f4d1;
        padding: 10px 15px;
        margin: 10px 0 10px;
    }
}

/* 'md': '768px', */
@media (min-width: 768px) {}

/* 'lg': '1024px', */
/* // => @media (min-width: 1024px) { ... } */

/* 'xl': '1280px', */
/* // => @media (min-width: 1280px) { ... } */

/* '2xl': '1536px', */
/* // => @media (min-width: 1536px) { ... } */