.service {
    margin: 50px auto 0;
}

.service_part_1_h1 {
    margin: 80px auto 15px;
    font-size: 32px;
    text-align: center;
}

.service_part_1_h1_bottom {
    text-align: center;
    margin: 0px auto 66px;
    border-bottom: 4px solid rgb(62, 121, 232);
    width: 70px;
}

.service_part_2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    padding: 0 20px;
}

.service_part_2_content {
    width: 300px;
    margin: 0 15px 55px;
    line-height: 27px;
}

.service_part_2_content>a {
    text-decoration: none;
    font-size: 24px;
    font-weight: 500;
}

.service_part_2_content>p {
    color: #1e293b;
}

.service ion-icon {
    font-size: 48px;
    color: blue;
}


@media only screen and (max-width: 600px) {

    .service {
        margin: 50px auto;
    }

    .service_part_1_h1 {
        margin: 20px auto 15px;
        font-size: 28px;
        text-align: center;
    }

    .service_part_1_h1_bottom {
        text-align: center;
        margin: 0px auto 36px;
        width: 70px;
    }

    .service_part_2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 100%;
        padding: 0 25px;
    }

    .service_part_2_content {
        width: auto;
        margin: 0 10px 35px;
        line-height: 27px;
    }

    .service_part_2_content>a {
        text-decoration: none;
        font-size: 20px;
        font-weight: 500;
    }

    .service ion-icon {
        font-size: 72px !important;
        color: rgba(0, 0, 255, 0.785);
    }

}