/* @media only screen and (min-width: 1101px) { */
main {
    padding: 0;
}

.home_part_1 {
    margin: 64px 0 0;
    background-image: url("../../assets/home/hero-bg.jpg");
    height: auto !important;
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    justify-content: center;
    /* height: 50vh; */

}

.home_part_1_color {
    margin: 0;
    padding: 200px 80px;
    background-color: rgba(0, 0, 255, 0.400);
    /* background-color: rgba(0, 0, 255, 0.285);*/
    height: inherit;
    /* height: 100vh; */
    width: 100%;

}

.home_part_1_h1 {
    font-size: 48px;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 45px;

}

.home_part_1_p {
    font-size: 27px;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 65px;
    border-left: 4px solid rgb(62, 121, 232);
    padding-left: 25px;
}

@keyframes heartbeat {

    0%,
    100% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(1.2);
    }

    75% {
        transform: scale(1.1);
    }
}

.home_part_1_btn {
    color: #ffffff;
    background-color: rgb(62, 121, 232);
    text-decoration: none;
    border: 1px solid transparent;
    border-color: rgb(62, 121, 232);
    border-radius: 50px;
    box-shadow: 1px 1px 1px 0.5px rgb(62, 121, 232);
    padding: 15px 45px;
    font-weight: 300;
    font-size: 18px;
    animation: heartbeat 1s ease-in-out 1s infinite alternate !important;
    display: inline-block;
    transition: transform 500ms;

}

/* ***********************Part 2 **************************** */

/* ***********************Part 3 **************************** */


/* ***********************Part 4 **************************** */

.home_part_4 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 100px auto 65px;
    width: 80%;
}


.home_part_4_left {
    width: 550px;
}

.home_part_4_left_1 {
    margin: 0 0 200px;
}

.home_part_4_left_1_h2 {
    font-size: 42px;
    margin-bottom: 30px;
}

.home_part_4_left_1_div {
    display: flex;
    flex-wrap: wrap;

}

.item {
    font-size: 20px;
    /* width: 275px; */
    letter-spacing: 1px;
    line-height: 55px;

}

.home_part_4_left_2 {
    margin-bottom: 30px;

}

.home_part_4_left_2_h2 {
    font-size: 42px;
    margin-bottom: 30px;
}

.home_part_4_left_2_p {
    font-size: 18px;
    margin-bottom: 45px;

}

.home_part_4_btn {
    color: #ffffff;
    background-color: rgb(62, 121, 232);
    text-decoration: none;
    border: 1px solid transparent;
    border-color: rgb(62, 121, 232);
    border-radius: 50px;
    box-shadow: 1px 1px 1px 0.5px rgb(62, 121, 232);
    padding: 15px 45px;
    font-weight: 300;
    font-size: 18px;
    animation: heartbeat 1s ease-in-out 1s infinite alternate !important;
    display: inline-block;
    transition: transform 500ms;
}



@media only screen and (max-width: 1101px) {
    .home_part_1 {
        margin: 49px 0 0;
    }
}

@media only screen and (max-width: 600px) {
    .home_part_1 {

        margin: 49px 0 0;
        height: auto;
    }

    .home_part_1_color {
        padding: 35px 20px;
        height: inherit;
        /* height: 28vh; */
        justify-content: center;
    }

    .home_part_1_h1 {
        font-size: 24px;
        /* margin-bottom: 15px; */

    }

    .home_part_1_p {
        font-size: 17px;
        margin-bottom: 35px;
        padding-left: 25px;
    }

    .home_part_1_btn {
        font-weight: 500;
        padding: 5px 15px;
        margin-bottom: 15px;
        font-size: 14px;
        transition: transform 500ms;

    }




    /* ***********************Part 4 **************************** */

    .home_part_4 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 10px auto 15px;
        padding: 10px 30px;
        width: 100%;
    }

    .home_part_4_left {
        justify-content: center;
        width: auto;
        /* width: 300px; */
        margin: 10px 0;

    }



    .home_part_4_left_1 {
        margin: 0 0 25px;
    }

    .home_part_4_left_1_h2 {
        font-size: 24px;
        margin-bottom: 30px;
        text-align: center;
        margin: 0 auto;
        padding: 0;
    }

    .home_part_4_left_1_div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;

    }

    .item {
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 35px;
        width: auto;
        text-align: center;

    }

    .home_part_4_left_2 {
        margin-bottom: 30px;

    }

    .home_part_4_left_2_h2 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .home_part_4_left_2_p {
        font-size: 14px;
        margin-bottom: 45px;
        /* width: 300px; */
        text-align: center;
    }

    .home_part_4_btn:hover {
        margin: 25px 0 0;
        /* margin: 25px 25px 0; */
        transform: none;
    }

    .home_part_4_btn {
        justify-content: center;

        padding: 15px 45px;
        font-size: 18px;
    }

    .home_part_4_img {
        height: 50vh;
        width: 250px;
        margin: 0 auto;
        justify-content: center;
    }

}