.myaccount {
    margin: 100px 0 10px;
    text-align: center;
}

.myaccount>ion-icon {
    font-size: 55px;
    color: #1e40af;
}

.myaccount .items {
    margin: 25px 0 50px;
    display: flex;
    justify-content: space-around;
}

.myaccount>.items>.link {
    font-size: 24px;
    color: #6b7280;


}

.myaccount>.items>.link:focus {
    border-bottom: 3px solid #1e40af;
    color: #0a0a0ad8;
    padding-bottom: 10px;
    text-decoration: none;
    width: 25%;
}