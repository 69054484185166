.error_container {
    margin: 145px auto 77px;
    background-color: #ffffff;
    text-align: center;
    opacity: 1;

}

.error_container>p {
    font-size: 18px;
}

.status {
    color: #6366f1;
    font-weight: 300;
    font-size: 200px;
    opacity: 0.5;

}