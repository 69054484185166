.user_dashbord {
    margin-top: 77px;
}

.info {
    display: flex;
}

.info_title {
    background-color: blueviolet;
    color: #ffffff;
    font-size: 25px;
    font-weight: 500;
    padding: 20px 20px;
}

.marquee {
    color: #ffffff;
    background-color: #4ade80;
    font-size: 18px;
    /* justify-content: center; */
    padding: 7px 0;
    margin-top: 18px;
}

@media (min-width: 700px) {
    .whyChooseUs {
        margin-top: -50px !important;
    }
}