/* Global Styles for Navbar */
nav {
    position: fixed;
    margin: 0;
    background-color: rgba(0, 0, 255, 0.254);
    z-index: 1000;
    top: 0;
    width: 100%;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 30px;
}

.logo_link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: left;
    width: 20%;
}

.title {
    font-size: 32px;
    font-weight: 700;
    color: #ffffff;
    background-color: transparent;
    margin-left: 50px;
    text-decoration: none;
    white-space: nowrap;
}

.part_1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    margin-right: 10%;
    background-color: transparent;
}

.part_1>.desktop-link {
    color: #ffffff;
    background-color: transparent;
    text-align: center;
    text-decoration: none;
    padding: 10px 30px;
    font-size: 18px;
    transition: transform 500ms;
    font-weight: bold;

}

.part_1>.desktop-link:hover {
    color: #ffffff;
    font-weight: bold;
    transform: scale(1.15);
}

.navbar-container .link {
    display: block;
    text-decoration: none;
}

/* ***************Part2********* */
.part_2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    min-width: 40%;
    padding: 0 4% 0 11%;
    background-color: transparent;

}

.part_2>.desktop-link {
    border: 1px solid transparent;
    border-color: #ffffff;
    border-radius: 7px;
    text-decoration: none;
    color: #ffffff;
    background-color: transparent;
    padding: 10px 20px;
    margin: 0 15px;
    font-size: 16px;
    transition: transform 500ms;

}

.part_2>.desktop-link:hover {
    color: blue;
    background-color: #ffffffd8;
    /* margin: 0 15px; */
    transform: scale(1.15);
}



/* ----------------------------- */
/* Styles pour Menu Mobile */


/* Styles pour Drawer Mobile */
.asly {
    position: relative;
}

.sousmenu {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: -75%;
    background-color: rgba(0, 0, 255, 0.254);
    border: 1px solid transparent;
    border-radius: 15px;
    padding: 25px 0;
    width: 250px;
    transition: visibility 0s, opacity 0.5s;
}

.sousmenu>.link {
    color: #ffffff;
    text-decoration: none;
    font-size: 24px;
    padding: 5px 0;
    width: 100%;
    transition: transform 500ms;
}

.sousmenu>.link:hover {
    color: #ffffff;
    font-size: 32px;
    font-weight: 600;
    transform: scale(1.15);
}







/*sous menu part */
/* Style for the "A-sly" menu */

.asly:hover .sousmenu {
    visibility: visible;
    display: flex;
    flex-direction: column;
    opacity: 1;
}