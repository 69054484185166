.data_container {
    margin: 0 auto;
    padding: 15px 25px;
    width: 600px;
    border: 1px solid #9ca3af;
    border-radius: 5px;
    background-color: #cbd5e1;
}

.datas {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;

}

.datas>h2 {
    min-width: 90px;
    margin: 10px 0;
}

.datas>p {
    font-size: 20px;
    margin: 10px 0;

}

@media (max-width: 768px) {
    .data_container {
        margin: 0 auto;
        padding: 15px 10px;
        width: 90%;
    }
}