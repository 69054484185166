.serviceWrapper {
    width: 100%;
}

.service_top {
    margin-top: -200px;
    background-image: url("../../assets/services/services-header.jpg");
    /* height: 85vh; */
    height: auto;
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position-y: left;
    background-size: cover;
}

.service_top_color {
    margin-top: 0px;
    padding-top: 350px;
    padding-bottom: 90px;
    background-color: rgba(0, 0, 255, 0.285);
    height: inherit;
    text-align: center;
}

.service_top_h1 {
    font-size: 48px;
    color: #ffffff;
    margin-bottom: 45px;

}

.service_top_div {
    display: flex;
    justify-content: center;
}


.service_top_div p {
    color: rgb(99, 198, 237);

}

.service_top_div .home_link {
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    text-decoration: none;
    margin-right: 4px;
}

.service_top_div>.home_link:hover {
    text-decoration: underline;
}

/* @media only screen and (max-width: 1101px) {

    .service_top {
        margin-top: 49px;


    }
} */


@media only screen and (max-width: 600px) {

    .service_top {
        margin-top: 49px;
        height: auto;
        /* background-size: contain;
        background-repeat: round; */

    }

    .service_top_color {
        margin-top: 0px;
        padding-top: 75px;
        height: inherit;
        text-align: center;
    }

    .service_top_h1 {
        font-size: 36px;
        margin-bottom: 15px;

    }

    .service_top_div {
        display: flex;
        justify-content: center;
    }

    .service_top_div .home_link {
        font-size: 18px;
        margin-right: 4px;
    }

    .service_top_div>.home_link:hover {
        text-decoration: underline;
    }



}