.users_wrapper {
    margin: 77px auto;
    text-align: center;
}

.users_wrapper h2 {
    border-bottom: 1px solid #d4d4d8;
    font-size: 27px;
    margin: 15px auto;
    padding: 15px;
    text-align: center;
}

.users_wrapper .link {
    color: #1d4ed8;
}

.users_item {
    display: flex;
    font-size: 18px;

    justify-content: space-between;
    margin: 15px auto;
    padding: 0 15px;
    width: 600px;
}


.modal-content {
    background-color: #2196F3;
    /* Bleu */
    padding: 20px;
    border-radius: 10px;
    width: 50%;
    max-width: 400px;
}


/**
  * Gestion des popups
  */

.popupBackground {
    display: flex;
    position: fixed;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    /* width: 100%;
    height: 100%; */
    background-color: rgba(0, 0, 0, 0.5);
}

.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 80%;
    max-width: 500px;
    padding: 20px;
    text-align: center;
    color: white;
    font-size: 17px;
    font-weight: 500;
    background-color: #2196F3;
    border-radius: 10px;
    z-index: 1;

}

.close {
    color: #ffffff;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
}

.popup h2 {
    width: 100%;
    margin-bottom: 20px;
}

.popup div {
    font-size: 25px;
    padding: 5px;
    padding-bottom: 15px;
}

.popup_form {
    display: flex;
    flex-direction: column;
}

.popup_form>label {
    text-align: center;
    margin: 10px auto;
}

/* Eléments de formulaire */
.popup_form>input {
    padding: 15px 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: none;
    /* width: calc(100% - 20px); */
    box-sizing: border-box;

}

.popup_btn {
    padding: 10px 20px;
    background-color: #ffffff;
    color: #2196F3;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* max-width: 360px; */

}

.popup_btn:hover {
    background-color: #f0f0f0;
}


@media (max-width: 768px) {
    .users_wrapper>h2 {
        border-bottom: 1px solid #d4d4d8;
        font-size: 18px;
        margin: 15px auto;
        /* padding: 15px; */
    }

    .users_item {
        display: flex;
        justify-content: space-between;
        margin: 15px 0;
        padding: 0 15px;
        width: auto;

    }

    .users_wrapper .link_container {
        flex-wrap: wrap;
        width: 50%;

    }


    .popup {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 80%;
        max-width: 400px;
        padding: 20px;
        text-align: center;
        color: white;
        font-size: 17px;
        font-weight: 500;
        background-color: #2196F3;
        border-radius: 10px;
        z-index: 1;
    }
}