.home_part_2 {
    margin: 0px 0px 65px;
    height: auto;
    /*added*/
}

.home_part_2_h1 {
    margin: 80px auto 15px;
    font-size: 32px;
    text-align: center;
}

.home_part_2_h1_bottom {
    text-align: center;
    margin: 0px auto 66px;
    border-bottom: 4px solid rgb(62, 121, 232);
    width: 70px;
}


.slider_part {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: inherit;
    padding: 0 105px;
    margin-bottom: 70px;

}

.home_part_2_img_container {
    /* margin: 10px auto; */
    background-image: url("../../assets/home/why-us-bg.jpg");
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    justify-content: center;
    /* background-size: contain; */
    height: inherit;
    /* height: 95vh; */
    width: 40%;
    /* margin-bottom: 70px; */


}

.slide_wrapper {
    width: 60%;
    height: inherit;
    /* height: 95vh; */
    padding: 125px 55px;
    justify-content: center;
    background-color: rgba(194, 194, 241, 0.236);
}

.slider_content {
    padding: 0px 25px;
    height: auto;
}

.slider_content_h2 {
    font-size: 36px;
    font-weight: 500;
}

.slider_content_h3 {
    font-size: 20px;
    margin: 35px 0;
    color: rgb(68, 77, 118);

}

.slider_content_p {
    /* height: inherit; */
    font-size: 18px;
    line-height: 25px;
}

/* @media only screen and (max-width: 600px) { */
/* @media (max-width: 640px) { */
@media (max-width: 600px) {

    .home_part_2 {
        margin: 0px 25px 15px;
    }

    .home_part_2_h1 {
        margin: 30px auto 15px;
        font-size: 24px;
        text-align: center;

    }

    .home_part_2_h1_bottom {
        text-align: center;
        margin: 0px auto 36px;
        border-bottom: 4px solid rgb(62, 121, 232);
        width: 70px;
    }


    .slider_part {
        display: flex;
        flex-direction: column;
        padding: 0 5px;
        margin-bottom: 0px;

    }

    .home_part_2_img_container {

        margin: 10px auto;
        /* background-size: contain;
        background-repeat: round; */
        height: 39vh;
        width: 100%;

        margin-bottom: 0px;

    }

    .slide_wrapper {
        /* width: 60%; */
        height: auto;
        width: 100%;
        padding: 0px 0px;
        background-color: #ffffff;
    }

    .slider_content {
        padding: 10px;
        background-color: rgba(194, 194, 241, 0.236);

    }

    .slider_content_h2 {
        font-size: 20px;
        font-weight: 500;
    }

    .slider_content_h3 {
        font-size: 18px;
        margin: 15px 0;
        color: rgb(68, 77, 118);

    }

    .slider_content_p {
        font-size: 16px;
        line-height: 20px;
    }
}