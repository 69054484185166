.home_part_3 {
    margin: 50px auto;
    background-image: url("../../assets/home/cta-bg.jpg");
    height: auto;
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position-y: left;
    background-size: cover;
    justify-content: center;
    text-align: center;
    opacity: 0.85;
}

.home_part_3_color {
    margin: 0;
    padding: 100px 0;
    background-color: rgba(0, 0, 255, 0.285);
    height: inherit;
    position: relative;
}

.home_part_3_h2 {
    margin: auto;
    font-size: 35px;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 45px;
    width: 600px;
    line-height: 45px;

}

.home_part_3_p {
    margin: auto;
    font-size: 25px;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 65px;
    width: 450px;
    line-height: 30px;

}

.home_part_3_btn_div {
    position: absolute;
    margin: 0 auto;
    width: 100%;
}

@keyframes heartbeat {

    0%,
    100% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(1.2);
    }

    75% {
        transform: scale(1.1);
    }
}

.home_part_3_btn {
    color: #ffffff;
    background-color: rgb(62, 121, 232);
    text-decoration: none;
    border: 1px solid transparent;
    border-color: rgb(62, 121, 232);
    border-radius: 50px;
    box-shadow: 1px 1px 1px 0.5px rgb(62, 121, 232);
    padding: 15px 45px;
    font-weight: 300;
    font-size: 18px;
    animation: heartbeat 1s ease-in-out 1s infinite alternate !important;
    display: inline-block;
    transition: transform 500ms;


}


@media only screen and (max-width: 600px) {
    .home_part_3 {
        /* background-size: contain;
        background-repeat: no-repeat; */
        margin: 50px auto;
        height: auto;
        /* height: 24vh; */
        justify-content: center;
    }

    .home_part_3_color {
        margin: 0;
        padding: 15px 20px;
        height: inherit;

    }


    .home_part_3_h2 {
        margin: 0;
        font-size: 24px;
        margin-bottom: 10px;
        padding: auto;
        line-height: 30px;
        text-align: center;
        width: auto;

    }

    .home_part_3_p {
        margin: auto;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
        padding: auto;
        line-height: 20px;
        width: auto;

    }

    .home_part_3_btn_div {
        position: unset;
        /* position: none; */
        margin: 0 auto;
        width: 100%;
    }

    .home_part_3_btn {

        padding: 5px 15px;
        font-size: 18px;

    }

}