.aboutWrapper {
    width: 100%;
}

.about_top {
    margin-top: -200px;
    background-image: url("../../assets/about/about-header.jpg");
    height: auto;
    /* height: 85vh; */
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position-y: left;
    background-size: cover;
}

.about_top_color {
    margin-top: 0px;
    padding-top: 350px;
    padding-bottom: 90px;
    background-color: rgba(0, 0, 255, 0.285);
    height: inherit;
    text-align: center;
}

.about_top_h1 {
    font-size: 48px;
    color: #ffffff;
    margin-bottom: 45px;

}

.about_top_div {
    display: flex;
    justify-content: center;
}


.about_top_div p {
    color: rgb(99, 198, 237);

}

.about_top_div .about_link {
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    text-decoration: none;
    margin-right: 4px;
}

.about_top_div>.about_link:hover {
    text-decoration: underline;
}

/* ---------------------------------------------- */
.about_part_1 {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-around;
    min-width: 300px;
    margin-top: 87px;
    padding: 0 50px;
}

.about_part_1_img>img {
    height: 35vh;
    width: auto;
}

.about_part_1_des {
    width: 600px;
}

.about_part_1_des>h1 {
    margin-bottom: 30px;
    font-size: 36px;
}

.about_part_1_des>h3 {
    margin-bottom: 30px;
    font-weight: 500;
}

.about_part_1_des>p {
    line-height: 25px;
    margin-bottom: 30px;

}


/* @media only screen and (max-width: 1101px) {

    .about_top {
        margin-top: 49px;

    }
} */

@media only screen and (max-width: 600px) {

    .about_top {
        margin-top: 49px;
        height: auto;
        /* background-size: contain;
        background-repeat: round; */

    }

    .about_top_color {
        margin-top: 0px;
        padding-top: 75px;
        height: inherit;
        text-align: center;

    }

    .about_top_h1 {
        font-size: 36px;
        margin-bottom: 15px;
    }

    .about_top_div .about_link {
        font-size: 18px;
        margin-right: 4px;
    }

    /* ---------------------------------------------- */
    .about_part_1 {
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: space-around;
        min-width: 300px;
        margin-top: 37px;
        padding: 0 50px;
    }

    .about_part_1_img>img {
        height: 27vh;
        width: auto;
    }

    /* .about_part_1_des {
        width: 600px;
    } */

    .about_part_1_des>h1 {
        margin-bottom: 15px;
        font-size: 24px;
    }

    .about_part_1_des>h3 {
        margin-bottom: 25px;
    }

    .about_part_1_des>p {
        font-size: 14px;
        line-height: 25px;
        margin-bottom: 25px;

    }
}