.insuranceWrapper {
    width: 100%;
}

.insurance_top {
    margin-top: -200px;
    background-image: url("../../assets/insurance/blog-header.jpg");
    height: auto;
    /* height: 85vh; */
    background-color: rgb(43, 24, 13);
    background-repeat: no-repeat;
    background-position-y: left;
    background-size: cover;
}

.insurance_top_color {
    margin-top: 0px;
    padding-top: 350px;
    padding-bottom: 90px;
    background-color: rgba(0, 0, 255, 0.285);
    height: inherit;
    text-align: center;
}

.insurance_top_h1 {
    font-size: 48px;
    color: #ffffff;
    margin-bottom: 45px;

}

.insurance_top_div {
    display: flex;
    justify-content: center;
}


.insurance_top_div p {
    color: blue;

}

.insurance_top_div .home_link {
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
    text-decoration: none;
    margin-right: 4px;
}

.insurance_top_div>.home_link:hover {
    text-decoration: underline;
}

.insurance_bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin: 86px auto;
    padding: 0 120px;

}

.insurance_bottom_item {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin-bottom: 35px;
    padding-bottom: 35px;
    border-bottom: 1px solid #cbd5e1;
}

.insurance_bottom_item>img {
    height: 40vh;
    width: auto;
}

.insurance_bottom_item>h3 {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 500;
}

.insurance_bottom_item>h3:hover {
    color: rgba(0, 0, 255, 0.705);
    transition: 400ms;
    cursor: pointer;
}

.insurance_bottom_item>p {
    margin: 15px auto 15px;
    line-height: 25px;
    color: #334155;
}

.insurance_bottom_item>.read_more_link {
    text-align: right;
    color: rgba(0, 0, 255, 0.705);
    text-decoration: none;
    letter-spacing: 1px;
}


@media only screen and (max-width: 1101px) {

    .insurance_bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 86px auto;
        padding: 0 120px;
        width: 70%;
    }
}

@media only screen and (max-width: 600px) {

    .insurance_top {
        margin-top: 49px;
        height: auto;
        /* background-size: contain;
        background-repeat: round; */

    }

    .insurance_top_color {
        margin-top: 0px;
        padding-top: 75px;
        height: inherit;
        text-align: center;
    }

    .insurance_top_h1 {
        font-size: 36px;
        margin-bottom: 15px;

    }

    .insurance_top_div .home_link {
        font-size: 18px;
        margin-right: 4px;
    }


    /* ----------------Part 2------------------- */
    .insurance_bottom {
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap; */
        /* justify-content: center;
        align-items: center; */
        margin: 46px auto;
        padding: 0 10px;
        width: 350px;
    }

    .insurance_bottom_item {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0px;
        padding-bottom: 35px;
        border-bottom: 1px solid #cbd5e1;
    }

    .insurance_bottom_item>img {
        height: 35vh;
        width: auto;
    }

    .insurance_bottom_item>h3 {
        margin-top: 15px;
        font-size: 20px;
        font-weight: 500;
    }


    .insurance_bottom_item>p {
        margin: 15px auto 15px;
        line-height: 25px;
        color: #334155;
    }

    .insurance_bottom_item>.read_more_link {
        text-align: right;

    }
}