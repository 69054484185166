.loginContainer {
    margin: 64px auto 0px;
    background-image: url("../../assets/login/login.avif");
}

.login_sub_container {
    display: flex;
    justify-content: space-around;
    padding: 100px 50px;
    margin: 0 auto;
    background-color: rgba(98, 98, 220, 0.706);
    height: inherit;
    width: inherit;
}

.login_left {
    /* width: 575px; */
    width: 50%;
    padding: 100px 0 0 105px;
}

.login-title-1 {
    font-size: 48px;
    font-weight: 600;
    color: #f4f1f1;
}

.login-title-2 {
    font-size: 48px;
    font-weight: 600;
    line-height: 55px;
    color: rgb(114, 204, 227);
    margin-bottom: 65px;
}

.login-title-3 {
    font-size: 36px;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 10px;
}

.login-title-message {
    font-size: 18px;
    color: antiquewhite;
    line-height: 20px;
    word-spacing: 2px;
}

.login_form {
    /* width: 400px; */
    width: 40%;
    margin: 10px auto;
    padding: 25px 45px;
    background-color: #f8f9fa;
    border-radius: 5px;
}

.login_form>h1 {
    margin-bottom: 35px;
    font-family: 'Times New Roman', Times, serif;
    font-style: italic;
    font-size: 42px;
    font-weight: 700;
    text-align: center;
    color: rgba(43, 86, 226, 0.794);
    text-decoration: underline;
}

.login_form>div {
    margin-bottom: 25px;
}

.login_form>div>label {
    font-size: 18px;
}

.login_form>div>input:not([type=checkbox]) {
    display: block;
    margin-top: 15px;
    padding: 10px 7px;
    width: 100%;
    font-size: 16px;
    border: 1px solid rgba(139, 139, 139, 0.454);
    border-radius: 5px;
    accent-color: rgba(0, 0, 255, 0.656);
    /* accent-color: #ffffff; */
}

.login_form>div>input:not([type=checkbox]):focus {
    border: 1px solid transparent;
}

.login_btn {
    display: block;
    margin: 10px auto 30px;
    border: 1px solid transparent;
    border-color: blue;
    border-radius: 7px;
    text-decoration: none;
    color: #ffffff;
    background-color: rgba(0, 0, 255, 0.656);
    padding: 10px;
    font-size: 16px;
    transition: transform 200ms;
    text-align: center;
    width: 100%;
}

.forgot_password {
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 30px;
}

.forgot_password>a {
    text-decoration: none;
    color: blue;
}

@media only screen and (max-width: 1101px) {

    .loginContainer {
        padding: auto 25px;
        margin: 47px 0 0px;


    }
}

@media only screen and (max-width: 600px) {

    .loginContainer {
        padding: auto 25px;
        margin: 47px 0 0px;
        background-size: contain;
        background-repeat: round;

    }

    .login_sub_container {
        display: flex;
        flex-direction: column;
        padding: 10px 25px;
        margin: 0 auto;
    }

    .login_left {
        text-align: center;
        width: 100%;
        padding: 15px 0;
    }

    .login-title-1 {
        font-size: 24px;
        font-weight: 600;
        color: #f4f1f1;
    }

    .login-title-2 {
        font-size: 22px;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 25px;
    }

    .login-title-3 {
        font-size: 21px;
        margin-bottom: 10px;
    }

    .login-title-message {
        font-size: 16px;
        line-height: 17px;
        word-spacing: 2px;
    }

    .login_form {
        width: 100%;
        margin: 10px 0 10px;
        padding: 25px 15px;
        background-color: #f8f9fa;
        border-radius: 5px;
    }

    .login_form>h1 {
        margin-bottom: 25px;
        font-size: 36px;

    }

    .login_form>div {
        margin-bottom: 15px;
    }

    .login_form>div>label {
        font-size: 16px;
    }

    .login_form>div>input:not([type=checkbox]) {
        margin-top: 10px;
        padding: 5px 7px;
        width: 100%;
        font-size: 16px;
    }


    .login_btn {
        display: block;
        margin: 10px auto 30px;
        padding: 10px;
        font-size: 16px;
        width: 100%;
    }

    .forgot_password {
        line-height: 25px;
    }

}