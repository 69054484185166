.footerWrapper {
    width: 100%;
    margin-top: 45px;
    /* margin-top: 77px; */
    background-color: rgba(156, 156, 246, 0.172);

}


.footer_top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 45px 25px 25px;
    margin-bottom: 24px;
    /* background-color: rgba(156, 156, 246, 0.073); */
}

.footer_top_col_1 {
    width: 450px;
    /* margin-bottom: 45px; */
    line-height: 25px;
}

.footer_top_col_1 p {
    color: #334155;
}

.footer_top_col {
    /* margin-bottom: 45px; */
    line-height: 35px;
}

.footer_top_col .link {
    text-decoration: none;
    margin-bottom: 25px;
    color: rgb(35, 34, 49);
}

.footer_top_col>.link:hover {
    text-decoration: underline;
}

.footer_bottom {
    text-align: center;
    line-height: 25px;
    margin: 35px auto;
}

.footer_bottom>p {
    color: #64748b;
}

.footerWrapper ion-icon {
    font-size: 24px;
    color: rgba(0, 0, 255, 0.668);
    margin: 5px;
}

.langue_btn {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.702) !important;
}

.langue_btn:hover {
    font-size: 26px;
    cursor: pointer;
}

.langueBoxContainer {
    height: 45px;
    width: 150px;
    margin-left: 50px;
    margin-bottom: 7px;
}

.langueBoxContainer>select {
    background-color: #ffffff;
    /* opacity: 1; */
    font-weight: 500;
    border: 1px solid transparent;
    font-size: 18px;
    padding: 7px 10px;
}

.langueBoxContainer>select>option:hover {
    background-color: #5d74b8;
}

@media only screen and (max-width: 600px) {
    .footerWrapper {
        padding: auto 15px;
        margin-top: 25px;
        /* margin-top: 77px; */
    }


    .footer_top {
        display: flex;
        flex-direction: column;
        padding: 45px 25px 15px;
        margin-bottom: 24px;
    }

    .footer_top_col_1 {
        width: 350px;
        /* margin-bottom: 45px; */
        justify-content: center;
        align-items: center;

        line-height: 25px;
    }

    .footer_top_col {
        /* margin-bottom: 45px; */
        line-height: 35px;
    }

    .footer_top_col .link {
        text-decoration: none;
        margin-bottom: 5px;

    }

    .footerWrapper h2 {
        margin-top: 25px;
    }

    .footer_bottom {
        text-align: center;
        line-height: 25px;
        margin: 15px auto;
    }

    .footerWrapper ion-icon {
        font-size: 24px !important;
        color: rgba(0, 0, 255, 0.668);
        margin: 5px;
    }

    .langueBoxContainer {
        margin-left: 20px;
    }

}